<ngx-smart-modal #confirmationModal identifier="confirmationModal" 
    customClass="nsm-centered" 
    (onOpen)="getConfirmDetail()"
    (onClose)="reset()"
    (onAnyCloseEvent)="reset()"
    [closable]="false">
    <div *ngIf="confirmation_detail && confirmation_detail.edit_details " class="w-400-px sarabun rounded-3xl text-base comfirm-bg flex flex-col items-center relative">
        <div class="absolute right-0 top-0 ">
            <button class="btn-orange-close custom-btn" (click)="reset()">
                <span class="material-icons text-base">
                    close
                </span>
            </button>
        </div>
        <div class="flex flex-col space-y-3 w-full mt-3">
            <div class="col-span-full">
                <h2 class="text-base t">{{ confirmation_detail.edit_details.title }}</h2>
            </div>
            <div class="col-span-full text-left">
                <p class="text-base lh-24-px " >{{ confirmation_detail.edit_details.content }}</p>
            </div>
            <div class="col-span-full">
                <div class="flex items-center justify-end space-x-2">
                    <div class="">
                        <button  type="button" (click)="main_action()" class="custom-btn py-2 px-3 w-full btn-dark-orange rounded">
                            <span class="fw-600 fs-14-px lh-18-px sarabun">
                                {{ confirmation_detail.edit_details.btn_save }}
                            </span>
                        </button>
                    </div>
                    <div class="">
                        <button type="button" *ngIf="!isLoading" (click)="reset()" class="custom-btn py-2 px-3 w-full btn-dark-orange rounded">
                            <span class="fw-600 fs-14-px lh-18-px sarabun">
                                {{ confirmation_detail.edit_details.btn_cancel }}
                            </span>
                        </button>
                        <button type="button" *ngIf="isLoading"  class="custom-btn py-2 px-3 w-full btn-main-blue rounded flex justify-center items-center" style="display: flex;">
                            <mat-spinner class="pink-loader text-center" diameter="20" color="accent" mode="indeterminate"></mat-spinner>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- <div class="text-xl sarabun-bold mt-5">{{ confirmation_detail.edit_details.title }}</div>
        <div class="my-5 text-center">
            <p class="sarabun fw-400 fs-16-px lh-24-px body-text">{{ confirmation_detail.edit_details.content }}</p>
        </div>
        <div class="flex items-center justify-start px-2">
            <button (click)="reset()"
                class="mr-3 py-3 flex items-center px-6 focus:outline-none h-44-px custom-btn btn-cancel" >{{ confirmation_detail.edit_details.btn_cancel }}
            </button>
            <button *ngIf="!isLoading" (click)="main_action()" 
                class="custom-btn btn-red w-165-px h-44-px py-3 px-6 focus:outline-none">{{ confirmation_detail.edit_details.btn_save }}</button>
            <button class="custom-btn btn-red w-165-px h-44-px py-3 focus:outline-none" *ngIf="isLoading">
                <mat-spinner class="white-loader" diameter="20" color="accent" mode="indeterminate"></mat-spinner>
            </button>

        </div> -->
    </div>                
</ngx-smart-modal>
