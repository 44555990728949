import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  daily_overview=[
    {
      title: 'buy load',
      value: '22,000.00',
    },
    {
      title: 'cash in',
      value: '22,000.00',
    },
    {
      title: 'cash out',
      value: '22,000.00',
    },
    {
      title: 'megareward',
      value: '22,000.00',
    },
    {
      title: 'pay bills',
      value: '22,000.00',
    },
    {
      title: 'recieve money',
      value: '22,000.00',
    },
    {
      title: 'scan qr',
      value: '-',
    },
    {
      title: 'stores',
      value: '22,000.00',
    },
    {
      title: 'transfer funds',
      value: '22,000.00',
    },
  ];
  pending=[
    {
      title: 'cash in',
      validation: '22.00',
      approval: '22.00',
    },
    {
      title: 'cash out',      
      validation: '22.00',
      approval: '22.00',
    },
    {
      title: 'kyc appoval',
      validation: '-',
      approval: '22.00',
    },
  ];
  megapay=[
    {
      title: 'andoid download',
      value: '22,000.00',
    },
    {
      title: 'ioa download',      
      value: '22,000.00',
    },
    {
      title: 'active accounts',
      value: '-',
    },
  ];
  mg_wallet_monitoring=[
    {
      title: '2c2p wallet',
      value: '22,000.00',
    },
    {
      title: 'bpi wallet',      
      value: '22,000.00',
    },
    {
      title: 'dragon pay wallet',
      value: '-',
    },
    {
      title: 'ec-link wallet',      
      value: '22,000.00',
    },
    {
      title: 'mother wallet',      
      value: '22,000.00',
    },
    {
      title: 'otc wallet',      
      value: '22,000.00',
    },
    {
      title: 'unionbank wallet',      
      value: '22,000.00',
    },
  ];
  partner_wallet_monitoring=[
    {
      title: 'bayad center virtual wallet',
      value: '22.00',
      doi: '22',
    },
    {
      title: 'ecpay virtual wallet',      
      value: '22.00',
      doi: '22',
    },
    {
      title: 'rci globe',
      value: '250,000.00',
      doi: '22',
    },
    {
      title: 'rci smart',
      value: '-',
      doi: '22',
    },
    {
      title: 'rci sun',
      value: '-',
      doi: '22',
    },
  ];
  suspicious_transaction=[
    {
      name: 'michael reyes',
      transaction: 'cash in more than 20k',
      date: '1625903213000',
    },
    {
      name: 'michael reyes',      
      transaction: 'cash in more than 20k',
      date: '1625903213000',
    },
    {
      name: 'michael reyes',
      transaction: 'cash in more than 20k',
      date: '1625903213000',
    },
    {
      name: 'michael reyes',
      transaction: 'cash in more than 20k',
      date: '1625903213000',
    },
    {
      name: 'michael reyes',
      transaction: 'cash in more than 20k',
      date: '1625903213000',
    },
  ];


  constructor() { }

  ngOnInit(): void {
  }

}
