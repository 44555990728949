import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-buyload',
  templateUrl: './admin-buyload.component.html',
  styleUrls: ['./admin-buyload.component.scss']
})
export class AdminBuyloadComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }
  }

}
