<div class="w-full h-full">
    <div class="px-8">
        <div class="grid grid-cols-9 gap-3">
            <div class="col-span-5">                
                <div class="card-input flex items-center text-sm py-2 w-11/12 mb-3 ">
                    <div class="flex items-center space-x-4 ">
                    <span class="text-sm w-100-px">
                        Provider: 
                    </span>
                        <div #t1="matMenuTrigger" #select1 [matMenuTriggerFor]="type_select" class="  rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!providerDesc_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                            <span *ngIf="providerDesc_text" class="barlow text-black text-xs font-lg p-5 italic ">{{providerDesc_text}}</span>
                            <span class="material-icons violet-text " >
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #type_select="matMenu" >
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic"  >
                                == Select an option ==
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none italic "  (click)="setLoadProvider('Default ( Resound for Globe, Digilink for Smart/Sun/TNT ) ','0')">
                                Default (Resound for Globe, Digilink for Smart/Sun/TNT)
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic"  (click)="setLoadProvider(' Digilink ( Digilink For All Network )','1')">
                                Digilink (Digilink For All Network)
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic"  (click)="setLoadProvider('Resound ( Resound For All Network )','2')">
                                Resound (Resound For All Network)
                             </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="col-span-4">
                <div class="w-full h-full flex items-end justify-end">
                    <button  (click)="switchLoadProvider()" class="uppercase custom-btn btn-dark-orange font-semibold text-sm px-6 py-2">
                        submit
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="px-8 mt-10 mb-32">
        <button class="custom-btn btn-light-red px-4 py-1 text-sm" (click)="toggleDisplay()">
            <span class="material-icons text-white mr-1">
                add_circle
            </span>
            Add Buy Load SKU
        </button>
        <form [hidden]="isDisplay" [formGroup]="buyLoadForm">
        <div  class="grid grid-cols-12 gap-3 pl-4 mt-6">
            <div class="col-span-12">
                <div class="card-input flex items-center text-sm py-2 w-1/2">
                    <div class="flex items-center space-x-3">
                    <span class="text-sm w-90-px">
                        Load Provider:
                    </span>
                        <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="load_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!provider_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                            <span *ngIf="provider_text" class="barlow text-black text-xs font-lg p-5 italic">{{provider_text}}</span>
                            <span class="material-icons violet-text">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #load_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" >
                                == Select an option ==
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic"  (click)="setProvider('Default ( Resound for Globe, Digilink for Smart/Sun/TNT ) ','1')">
                                Default (Resound for Globe, Digilink for Smart/Sun/TNT)
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic"  (click)="setProvider(' Digilink ( Digilink For All Network )','2')">
                               Digilink (Digilink For All Network)
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic"  (click)="setProvider('Resound ( Resound For All Network )','3')">
                            Resound (Resound For All Network)
                             </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="col-span-4">
                <div class="card-input flex items-center text-sm py-2 w-full">
                    <span class="text-sm w-60-px">
                        Name:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" formControlName="itemName" type="text" placeholder="Enter Name">
                </div>
                <div class="card-input flex items-center text-sm py-2 w-full mb-3 mt-3">
                    <div class="flex items-center ">
                    <span class="text-sm w-120-px">
                        Network Provider:
                    </span>
                        <div #t3="matMenuTrigger" #select3 [matMenuTriggerFor]="network_select" class="rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!providerType_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                            <span *ngIf="providerType_text" class="barlow text-black text-xs font-lg p-5 italic">{{providerType_text}}</span>
                            <span class="material-icons violet-text mr-1">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #network_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" >
                                == Select an option ==
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setProviderType('Smart','1')">
                                Smart
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setProviderType('Globe','2')">
                                Globe
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setProviderType('Sun','3')">
                                Sun
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setProviderType('TNT','4')">
                                TNT
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setProviderType(' Smart Bro','5')">
                                Smart Bro
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setProviderType('Kuryente Load','6')">
                                Kuryente Load
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setProviderType('Cignal','7')">
                               Cignal
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setProviderType('DITO','8')">
                                DITO
                             </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="col-span-4">
                <div class="card-input flex items-center text-sm py-2 w-full">
                    <span class="text-sm w-80-px">
                        Item ID:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" formControlName="itemId"  type="text" placeholder="Enter ID">
                </div>
                <div class="card-input flex items-center text-sm py-2 w-full mb-3 mt-3">
                    <div class="flex items-center space-x-3">
                    <span class="text-sm w-100-px">
                        
                        Item Type:
                    </span>
                        <div  #t4="matMenuTrigger" #select4 [matMenuTriggerFor]="item_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!itemType_text"class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                            <span *ngIf="itemType_text"class="barlow text-black text-xs font-lg p-5 italic">{{itemType_text}}</span>
                            <span class="material-icons violet-text ml-3">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #item_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" >
                                == Select an option ==
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setItemType('Promo','1')">
                                Promo 
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic"  (click)="setItemType('Load','2')">
                                Load
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic"  (click)="setItemType('Text','3')">
                                Text
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setItemType('Combo','4')">
                                Combo
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic"  (click)="setItemType('Meralco','5')">
                               Meralco
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic"  (click)="setItemType('Cignal','6')">
                               Cignal
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setItemType(' Cignal PPV','7')">
                               Cignal PPV
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="col-span-4">
                <div class="card-input flex items-center text-sm py-2 w-full">
                    <span class="text-sm w-80-px">
                        Category:
                    </span>
                    <input [readonly]=true  class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"type="text" placeholder="Enter category">
                </div>
                <div class="card-input flex items-center text-sm py-2 w-full mt-3">
                    <span class="text-sm w-50-px">
                        Price:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" formControlName="itemPrice" type="number" placeholder="Enter price"n>
                </div>
            </div>
            <div class="col-span-12">
                <div class="card-input flex items-start text-sm py-2 w-full">
                    <span class="text-sm w-160-px">
                        Item Description:
                    </span>
                    <textarea class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 resize-none h-70-px" formControlName="itemDesc" placeholder="Enter Description" name="" id="" cols="30" rows="10"></textarea>
                </div>
            </div>
            
            <div class="col-span-12">
                <div class="w-full h-full flex items-end justify-center mb-5">
                    <button (click)="store2()" class="uppercase custom-btn btn-dark-orange font-semibold text-sm px-6 py-2">
                        submit
                    </button>
                </div>
            </div>
        </div>
    </form>
    </div>


    <div class="bg-light-orange w-full maxh-400-px py-4 px-3 overflow-x-auto w-table">
        <div class="w-full flex items-center justify-between space-x-3 pr-3 text-sm">
            <div class="flex items-center" [formGroup]="limitForm">
                <div class="orange-text">show:</div>
                <input class="orange-input focus:outline-none px-2 py-1 mx-2 w-60-px" type="number" formControlName="limit" (change)="limitKey($event)" value="{{size}}">
                <div class="orange-text">entries</div>

            </div>
            <div class="flex items-center space-x-3" [formGroup]="searchForm" (keydown)="keyDownFunction($event)">
                <div class="orange-text">Search:</div>
                <input class="orange-input focus:outline-none px-2 py-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey($event)">
            </div>
        </div>
        <div class="data-table mt-3 barlow text-xs">
            <table class="w-full">
                <thead class="capitalize">
                    <tr>
                        <th class="text-left barlow">
                            NAME
                        </th>
                        <th class="text-left barlow">
                            item type
                        </th>
                        <th class="text-left barlow">
                            Item ID
                        </th>
                        <th class="text-left barlow">
                            category
                        </th>
                        <th class="text-left barlow">
                            Description
                        </th>
                        <th class="text-left barlow">
                            price
                        </th>
                        <th class="text-left barlow">
                            load provider
                        </th>
                        <th class="text-left barlow">
                            network provider
                        </th>
                        <th class="text-left barlow">
                            sort
                        </th>
                        <th class="text-left barlow">
                            status
                        </th>
                        <th class="text-left barlow">
                            image url
                        </th>
                        <th class="text-left barlow">
                            ACTION
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container >
                        <tr class="focus:outline-none" *ngFor="let buyLoad of buyLoad_lists " >
                            <td >{{buyLoad.itemName}}</td>
                            <td *ngIf="buyLoad.itemType">
                                <p *ngIf="buyLoad.itemType==1">Promo</p>
                                <p *ngIf="buyLoad.itemType==2">Load</p>
                                <p *ngIf="buyLoad.itemType==3">Text</p>
                                <p *ngIf="buyLoad.itemType==4">Combo</p>
                                <p *ngIf="buyLoad.itemType==5">Meralco</p>
                                <p *ngIf="buyLoad.itemType==6">Cignal</p>
                                <p *ngIf="buyLoad.itemType==7">Cignal PPV</p>  
                            </td>

                            <td >{{buyLoad.itemId}}</td>
                            <td >{{buyLoad.itemCategory}}</td>
                            <td >{{buyLoad.itemDesc}}</td>
                            <td >{{buyLoad.itemPrice}}</td>
                            <td *ngIf="buyLoad.provider">
                                <p *ngIf="buyLoad.provider==0"> Default (Resound for Globe, Digilink for Smart/Sun/TNT)</p>
                                <p *ngIf="buyLoad.provider==1"> Digilink (Digilink For All Network)</p>
                                <p *ngIf="buyLoad.provider==2"> Resound (Resound For All Network)</p>
                            </td>
                            <td *ngIf="buyLoad.providerType">
                                <p *ngIf="buyLoad.providerType==1">Smart</p>
                                <p *ngIf="buyLoad.providerType==2">Globe</p>
                                <p *ngIf="buyLoad.providerType==3">Sun</p>
                                <p *ngIf="buyLoad.providerType==4">TNT</p>
                                <p *ngIf="buyLoad.providerType==5">Smart Bro</p>
                                <p *ngIf="buyLoad.providerType==6">Kuryente Load</p>
                                <p *ngIf="buyLoad.providerType==7">Cignal</p> 
                                <p *ngIf="buyLoad.providerType==8">DITO</p> 
                            </td>
                            <td >{{buyLoad.sort}}</td>
                            <td>
                                <p *ngIf="buyLoad.status==0">Active</p>
                                <p *ngIf="buyLoad.status!==0">Inactive</p>
                            </td>
                            <td >{{buyLoad.imageUrl}}</td>
                            <td class="text-xs" >
                                <ng-container *ngIf="buyLoad" >
                                    <button class="power-btn" *ngIf="buyLoad.status==0" (click) = "changeStatus(buyLoad.uid,1)">
                                        <span class="material-icons text-sm" >
                                            power_settings_new
                                        </span>
                                    </button>
                                    <button class="power-btn active"  *ngIf="buyLoad.status==1" (click) = "changeStatus(buyLoad.uid,0)">
                                        <span class="material-icons text-sm" >
                                            power_settings_new
                                        </span>
                                    </button>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                    <!-- <ng-container *ngIf="isLoading">
                        <ng-container *ngFor="let item of [1,2,3,4]">
                            <tr>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container> -->
                </tbody>
            </table>
        </div>
        <div class="col-span-full flex justify-between items-center">
            <div class="orange-text text-sm">
                showing {{size}} of {{total}} entree
            </div>
            <div class="pr-3 flex items-center justify-end table-pagination mt-6" style="text-align: right;">
            <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;" [disabled] ="this.current_page == 1" (click)="prevPage()">
                Previous
            </button>
            <ng-container *ngFor="let item of pages_array | slice:0:3; let i=index;">
                <button class="page-btn sarabun-bold orange-box px-2" [ngClass]="{'active':current_page == item}" style="white-space: nowrap;" (click) = "setPage(item)">
                    {{item}}
                </button>
            </ng-container>
            <ng-container *ngIf="total_pages>3&&current_page+1<total_pages">
                <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container *ngIf="total_pages>3&&current_page+1<total_pages">
                <button class="page-btn sarabun-bold orange-box px-2" [ngClass]="{'active': current_page == item}" style="white-space: nowrap;" (click) = "setPage(total_pages)">
                    {{total_pages}}
                </button> 
            </ng-container>
            <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;" [disabled]="this.current_page == total_pages " (click)= "nextPage()">
                Next 
            </button> 
          </div>
        </div>
    </div>
</div>