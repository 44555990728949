import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cashin-out',
  templateUrl: './cashin-out.component.html',
  styleUrls: ['./cashin-out.component.scss']
})
export class CashinOutComponent implements OnInit {

  selected_tab: any = 1;

  constructor() { }

  ngOnInit(): void {
  }

  selectTab(tab){
    this.selected_tab = tab
  }


}
