<div class="w-full h-full">
    <div class="w-700-px mx-auto mt-8">
        <form [formGroup]="announcementForm">
            <div class="grid grid-cols-12 gap-6">
                <div class="col-span-12 space-y-5">
                    <div class="card-input flex items-center text-sm py-2 w-full">
                        <span class="text-sm w-60-px">
                            URL:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="URL" formControlName="webViewUrl">
                    </div>
                    <div class="card-input flex items-start text-sm py-2 w-full">
                        <span class="text-sm w-110-px">
                            Description:
                        </span>
                        <textarea formControlName="description" class="placeholder-black focus:outline-none w-full h-70-px bg-gray-100 rounded-md pl-3 resize-none" placeholder="Description (optional)" name="" id="" cols="30" rows="10"></textarea>
                    </div>
                    <div class="flex flex-col items-start text-sm pl-5 mb-3">
                        <div class="flex items-center space-x-3">
                            <span>Cover Image: </span>
                            <input formControlName="image" type='file' class="hidden" (change)="onSelectFile($event)" #coverImg>
                            <button class="browse-btn px-4 py-2 focus:outline-none" (click)="coverImg.click()">
                                Browse...
                            </button>
                        </div>
                        <img [src]="img" class="w-full h-200-px object-contain mt-3" *ngIf="img"> <br/>
                    </div>
                </div>
                <div class="col-span-6">
                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-106-px">
                            Date From:
                        </span>
                        <!-- <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy"> -->
                        <mat-form-field color="accent" appearance="">
                            <input
                                class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                                type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker1" readonly
                                (click)="startDate.click()" formControlName="dateFrom">
                            <mat-datepicker-toggle matSuffix [for]="picker1" class="focus:outline-none">
                                <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                    id="iconfinder_calendar-book-date-monthly_2203551"
                                    xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                    <path id="Path_371" data-name="Path 371"
                                        d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                        fill="#5f4d9c" />
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                        transform="translate(13 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                        transform="translate(13 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                        transform="translate(8 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                        transform="translate(3 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                        transform="translate(8 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                        transform="translate(3 6.936)" fill="#5f4d9c" />
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker1></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-span-6">

                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-106-px">
                            Date To:
                        </span>
                        <mat-form-field color="accent" appearance="">
                            <input
                                class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                                type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker2" readonly
                                (click)="startDate.click()" formControlName="dateTo">
                            <mat-datepicker-toggle matSuffix [for]="picker2" class="focus:outline-none">
                                <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                    id="iconfinder_calendar-book-date-monthly_2203551"
                                    xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                    <path id="Path_371" data-name="Path 371"
                                        d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                        fill="#5f4d9c" />
                                    <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                        transform="translate(13 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                        transform="translate(13 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                        transform="translate(8 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                        transform="translate(3 11.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                        transform="translate(8 6.936)" fill="#5f4d9c" />
                                    <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                        transform="translate(3 6.936)" fill="#5f4d9c" />
                                </svg>
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker2></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="w-full flex items-center justify-center mt-6 mb-4 ">
        <button (click)="store()" class="uppercase custom-btn btn-dark-orange font-semibold text-base px-6 py-2">
            Submit
        </button>
    </div>

    <div class="bg-light-orange w-full maxh-400-px py-4 px-3 overflow-x-auto w-table">
        <div class="w-full flex items-center justify-between space-x-3 pr-3 text-sm">
            <div class="flex items-center" [formGroup]="limitForm">
                <div class="orange-text">show:</div>
                <input class="orange-input focus:outline-none px-2 py-1 mx-2 w-60-px" type="number"  formControlName="limit" (change)="limitKey($event)" value="{{size}}">
                <div class="orange-text">entries</div>
            </div>
            <div class="flex items-center space-x-3" [formGroup]="searchForm" (keydown)="keyDownFunction($event)">
                <div class="orange-text">Search:</div>
                <input class="orange-input focus:outline-none px-2 py-1" type="text" placeHolder="Search"  formControlName="search" (keyup)="searchKey($event)">
            </div>
        </div>
        <div class="data-table mt-3 barlow text-xs">
            <table class="w-full">
                <thead class="capitalize">
                    <tr>
                        <th class="text-left barlow">
                            Url
                        </th>
                        <th class="text-left barlow">
                            Description
                        </th>
                        <th class="text-left barlow">
                            date from
                        </th>
                        <th class="text-left barlow">
                            date to
                        </th>
                        <th class="text-left barlow">
                            image
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container >
                        <tr class="focus:outline-none" *ngFor="let announcements of announcement_lists">
                            <td >{{announcements.webViewUrl}}</td>
                            <td >{{announcements.description}}</td>
                            <td >{{announcements.dateFrom}}</td>
                            <td >{{announcements.dateTo}}</td>
                            <td><a href="{{announcements.imageUrl}}"><img src="{{announcements.imageUrl}}" style="width:100px"></a></td>
                            
                        </tr>
                    </ng-container>
                    <!-- <ng-container *ngIf="isLoading">
                        <ng-container *ngFor="let item of [1,2,3,4]">
                            <tr>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader >
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader >
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader >
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader>
                                        </app-loader>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container> -->
                </tbody>
            </table>
        </div>
        <div class="col-span-full flex justify-between items-center">
            <div class="orange-text text-sm">
                showing {{size}} of {{total}} entree
            </div>
          <div class="pr-3 flex items-center justify-end table-pagination mt-6" style="text-align: right;">
            <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;" [disabled] = "this.current_page == 1" (click) = "prevPage()">
                Previous 
            </button> 
            <ng-container *ngFor="let item of pages_array | slice:0:3; let i= index;">
                <button class="page-btn sarabun-bold orange-box px-2" [ngClass]="{'active': current_page == item}" style="white-space: nowrap;" (click) = "setPage(item)">
                    {{item}}
                </button> 
            </ng-container> 
            <ng-container *ngIf="total_pages>3&&current_page+1<total_pages">
                <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container *ngIf="total_pages>3&&current_page+1<total_pages">
                <button class="page-btn sarabun-bold orange-box px-2" [ngClass]="{'active': current_page == item}" style="white-space: nowrap;" (click) = "setPage(total_pages)">
                    {{total_pages}} 
                </button> 
            </ng-container>
            <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;" [disabled] = "this.current_page == total_pages" (click) = "nextPage()">
                Next 
            </button> 
          </div>
        </div>m
    </div>
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>