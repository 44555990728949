import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.scss']
})
export class KycComponent implements OnInit {

  selected_tab: any = 1;
  constructor() { }

  ngOnInit(): void {
  }

  selectTab(tab){
    this.selected_tab = tab
  }

}
