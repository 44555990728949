import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public router: Router
  ) { }
  canActivate(){  
    let username = JSON.parse(localStorage.getItem('username'));
    let password = JSON.parse(localStorage.getItem('password'));
    if( username && password){
      return true;
    } else {
      this.router.navigate(['/login'])
      return true;
    }
  }
}
