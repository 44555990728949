<div class="w-full h-full">
    <div class="w-700-px mx-auto mt-20">
        <div class="grid grid-cols-8 gap-3">
            <div class="col-span-8">
                <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                    <div class="flex items-center space-x-3">
                    <span class="text-sm w-60-px">
                        Switch:
                    </span>
                        <div #t3="matMenuTrigger" #select3 [matMenuTriggerFor]="payment_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span class="barlow text-black text-base font-lg p-5">== Select an option ==</span>
                            <span class="material-icons violet-text pr-5 tt-370">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #payment_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  " >
                                == Select an option == 
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  " >
                                ON
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  " >
                                OFF
                            </button>
                        </mat-menu>
                    </div>
                </div>
                <div class="w-full flex items-center justify-center mt-10 mb-4">
                    <button class="uppercase custom-btn btn-dark-orange font-semibold text-base px-6 py-2">
                        submit
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>