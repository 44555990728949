<div class="w-full h-full">
    <div class="px-8 mt-8">
        <button class="custom-btn btn-light-red px-4 py-1 text-sm">
            <span class="material-icons text-white mr-1">
                add_circle
            </span>
            Add Biller
        </button>
        <div class="grid grid-cols-12 gap-3 pl-4 mt-6">
            <div class="col-span-12">
                <div class="card-input flex items-center text-sm py-2 w-full">
                    <span class="text-sm w-180-px">
                        Product Description:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="Enter Description">
                </div>
            </div>
            <div class="col-span-6">
                <div class="card-input flex items-center text-sm py-2 w-full">
                    <span class="text-sm w-180-px">
                        Product Name:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="Product Name">
                </div>
            </div>
            <div class="col-span-6">
                <div class="card-input flex items-center text-sm py-2 w-full">
                    <span class="text-sm w-180-px">
                        Product Code:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="Product Code">
                </div>
            </div>

            <div class="col-span-4">
                <div class="card-input flex items-center text-sm py-2 w-full">
                    <span class="text-sm w-180-px">
                        Price:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="Enter Amount">
                </div>
            </div>
            <div class="col-span-4">
                <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                    <span class="text-sm w-106-px">
                        Sale Start Date:
                    </span>
                    <!-- <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy"> -->
                    <mat-form-field color="accent" appearance="">
                        <input
                            class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                            type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker1" readonly
                            (click)="startDate.click()">
                        <mat-datepicker-toggle matSuffix [for]="picker1" class="focus:outline-none">
                            <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                id="iconfinder_calendar-book-date-monthly_2203551"
                                xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                <path id="Path_371" data-name="Path 371"
                                    d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                    fill="#5f4d9c" />
                                <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                    transform="translate(13 6.936)" fill="#5f4d9c" />
                                <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                    transform="translate(13 11.936)" fill="#5f4d9c" />
                                <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                    transform="translate(8 11.936)" fill="#5f4d9c" />
                                <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                    transform="translate(3 11.936)" fill="#5f4d9c" />
                                <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                    transform="translate(8 6.936)" fill="#5f4d9c" />
                                <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                    transform="translate(3 6.936)" fill="#5f4d9c" />
                            </svg>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-span-4">
                <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                    <span class="text-sm w-106-px">
                        Sale End Date:
                    </span>
                    <mat-form-field color="accent" appearance="">
                        <input
                            class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                            type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker2" readonly
                            (click)="startDate.click()">
                        <mat-datepicker-toggle matSuffix [for]="picker2" class="focus:outline-none">
                            <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                id="iconfinder_calendar-book-date-monthly_2203551"
                                xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                <path id="Path_371" data-name="Path 371"
                                    d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                    fill="#5f4d9c" />
                                <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                    transform="translate(13 6.936)" fill="#5f4d9c" />
                                <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                    transform="translate(13 11.936)" fill="#5f4d9c" />
                                <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                    transform="translate(8 11.936)" fill="#5f4d9c" />
                                <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                    transform="translate(3 11.936)" fill="#5f4d9c" />
                                <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                    transform="translate(8 6.936)" fill="#5f4d9c" />
                                <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                    transform="translate(3 6.936)" fill="#5f4d9c" />
                            </svg>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-span-6">
                <div class="card-input flex items-center text-sm py-2 w-full">
                    <div class="flex items-center space-x-3">
                    <span class="text-sm w-60-px">
                        Category:
                    </span>
                        <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="load_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                            <span class="material-icons violet-text tt-220">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #load_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" >
                                == Select an option ==
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" >
                                Approve
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" >
                                Pending
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" >
                                Rejected
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>

            <div class="col-span-6">
                <div class="card-input flex items-center text-sm py-2 w-full">
                    <span class="text-sm w-110-px">
                        Image URL:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="Enter URL (Please discord 'https://')">
                </div>
            </div>

            <div class="col-span-12">
                <div class="w-full h-full flex items-end justify-center mb-5">
                    <button class="uppercase custom-btn btn-dark-orange font-semibold text-sm px-6 py-2">
                        submit
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="bg-light-orange w-full maxh-400-px py-4 px-3 overflow-x-auto w-table">
        <div class="w-full flex items-center justify-between space-x-3 pr-3 text-sm">
            <div class="flex items-center">
                <div class="orange-text">show:</div>
                <input class="orange-input focus:outline-none px-2 py-1 mx-2 w-35-px" type="text">
                <div class="orange-text">entries</div>

            </div>
            <div class="flex items-center space-x-3">
                <div class="orange-text">Search:</div>
                <input class="orange-input focus:outline-none px-2 py-1" type="text">
            </div>
        </div>
        <div class="data-table mt-3 barlow text-xs">
            <table class="w-full">
                <thead class="capitalize">
                    <tr>
                        <th class="text-left barlow">
                            NAME
                        </th>
                        <th class="text-left barlow">
                            code
                        </th>
                        <th class="text-left barlow">
                            store
                        </th>
                        <th class="text-left barlow">
                            category
                        </th>
                        <th class="text-left barlow">
                            price
                        </th>
                        <th class="text-left barlow">
                            start date
                        </th>
                        <th class="text-left barlow">
                            end date
                        </th>
                        <th class="text-left barlow">
                            Image url
                        </th>

                        <th class="text-left barlow">
                            action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container >
                        <tr class="focus:outline-none" *ngFor="let item of [1,2,3,4]" >
                            <td >Name</td>
                            <td >Name</td>
                            <td >Name</td>
                            <td >Name</td>
                            <td >Name</td>
                            <td >Name</td>
                            <td >Name</td>
                            <td >Name</td>
                            <td >
                                <button class="power-btn">
                                    <span class="material-icons text-sm">
                                        power_settings_new
                                    </span>
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                    <!-- <ng-container *ngIf="isLoading">
                        <ng-container *ngFor="let item of [1,2,3,4]">
                            <tr>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container> -->
                </tbody>
            </table>
        </div>
        <div class="col-span-full flex justify-between items-center">
            <div class="orange-text text-sm">
                showing 1 to 10 of 400 entree
            </div>
          <div class="pr-3 flex items-center justify-end table-pagination mt-6" style="text-align: right;">
            <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;">
                Previous 
            </button> 
            <button class="page-btn sarabun-bold orange-box px-2 active" style="white-space: nowrap;">
                1 
            </button> 
            <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;">
                2 
            </button> 
            <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;">
                3 
            </button> 
            <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;">
                4 
            </button> 
            <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;">
                5 
            </button> 
            <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;">
                Next 
            </button> 
          </div>
        </div>
    </div>
</div>