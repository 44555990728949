<div class="w-screen h-screen overflow-hidden">
    <div class="flex flex-row h-full w-full overflow-hidden">
        <div class="transition duration-500 ease-in-out w-250-px h-screen z-20">
            <app-sidenav></app-sidenav>
        </div>
        <div class="flex flex-col max-w-full template" >
            <app-header></app-header>
            <div class="default-bg-color overflow-y-auto overflow-x-hidden main-content w-full">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>