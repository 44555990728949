import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-buy-load',
  templateUrl: './buy-load.component.html',
  styleUrls: ['./buy-load.component.scss']
})
export class BuyLoadComponent implements OnInit {


  transaction:any =[
    {
      id: '00001',
      date: 1625984309000,
      name: 'michael reyes',
      phone_number: '09206428863',
      recipient_number: '09206428863',
      product_id: 'UTP15',
      item_type: 'load',
      load_provider: 'TNT',
      amount: '15.00',
      trace_number: 'SDFGD826G',
      type: 'load',
      status: 'success',
      date_modified: 1625984309000,
    },
    {
      id: '00001',
      date: 1625984309000,
      name: 'michael reyes',
      phone_number: '09206428863',
      recipient_number: '09206428863',
      product_id: 'UTP15',
      item_type: 'load',
      load_provider: 'TNT',
      amount: '15.00',
      trace_number: 'SDFGD826G',
      type: 'load',
      status: 'success',
      date_modified: 1625984309000,
    },
    {
      id: '00001',
      date: 1625984309000,
      name: 'michael reyes',
      phone_number: '09206428863',
      recipient_number: '09206428863',
      product_id: 'UTP15',
      item_type: 'load',
      load_provider: 'TNT',
      amount: '15.00',
      trace_number: 'SDFGD826G',
      type: 'load',
      status: 'success',
      date_modified: 1625984309000,
    },
  ]
  constructor() { }

  ngOnInit(): void {
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }
  }

}
