<div class="h-full w-full">
    <div class="px-8 py-5">
        <!-- tabs -->
        <div class="flex items-center space-x-1 text-sm justify-start mb-3">
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 1 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(1)">
                Buy load
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 2 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(2)">
                pay bills
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 3 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(3)">
                cash in
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 4 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(4)">
                stores
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 5 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(5)">
                redeem
            </button>
        </div>
    </div>
    <!-- rendered tab -->
    <div class="w-full h-full">
        <ng-container *ngIf="selected_tab == 1">
            <app-admin-buyload></app-admin-buyload>
        </ng-container>
        <ng-container *ngIf="selected_tab == 2">
            <app-admin-tp-pay-bills></app-admin-tp-pay-bills>
        </ng-container>
        <ng-container *ngIf="selected_tab == 3">
            <app-admin-tp-cash-in></app-admin-tp-cash-in>
        </ng-container>
        <ng-container *ngIf="selected_tab == 4">
            <app-admin-tp-stores></app-admin-tp-stores>
        </ng-container>
        <ng-container *ngIf="selected_tab == 5">
            <app-admin-tp-redeem></app-admin-tp-redeem>
        </ng-container>
        
    </div>
</div>