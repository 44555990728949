<div class="w-full h-full">
    <div class="w-900-px mx-auto">
        <div class="grid grid-cols-8 gap-3">
            <div class="col-span-4">
                <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                    <span class="text-sm w-130-px">
                        Transaction ID:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="Transaction ID">
                </div>
                <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                    <span class="text-sm w-60-px">
                        Name:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="User Name">
                </div>
                <div class="card-input flex items-center text-sm py-2 w-3/4 mb-3">
                    <span class="text-sm w-106-px">
                        Date From:
                    </span>
                    <!-- <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy"> -->
                    <mat-form-field color="accent" appearance="">
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker1" readonly (click)="startDate.click()" >
                        <mat-datepicker-toggle matSuffix [for]="picker1" class="focus:outline-none" >
                            <svg #startDate class="focus:outline-none" matDatepickerToggleIcon id="iconfinder_calendar-book-date-monthly_2203551" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                <path id="Path_371" data-name="Path 371" d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z" fill="#5f4d9c"/>
                                <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4" transform="translate(13 6.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4" transform="translate(13 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4" transform="translate(8 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4" transform="translate(3 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4" transform="translate(8 6.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4" transform="translate(3 6.936)" fill="#5f4d9c"/>
                            </svg>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="card-input flex items-center text-sm py-2 w-3/4 mb-3">
                    <div class="flex items-center space-x-3">
                    <span class="text-sm w-100-px">
                        Item Type:
                    </span>
                        <div #t1="matMenuTrigger" #select1 [matMenuTriggerFor]="type_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span class="barlow text-black text-base font-lg p-5">All</span>
                            <span class="material-icons violet-text">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #type_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  " >
                                All 
                            </button>
                        </mat-menu>
                    </div>
                </div>

            </div>
            <div class="col-span-4">
                <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                    <span class="text-sm w-141-px">
                        Phone Number:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="Phone Number">
                </div>

                <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                    <span class="text-sm w-130-px">
                        Product ID:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="Product ID">
                </div>
                <div class="card-input flex items-center text-sm py-2 w-3/4 mb-3">
                    <span class="text-sm w-106-px">
                        Date To:
                    </span>
                    <mat-form-field color="accent" appearance="">
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker2" readonly (click)="startDate.click()" >
                        <mat-datepicker-toggle matSuffix [for]="picker2" class="focus:outline-none" >
                            <svg #startDate class="focus:outline-none" matDatepickerToggleIcon id="iconfinder_calendar-book-date-monthly_2203551" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                <path id="Path_371" data-name="Path 371" d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z" fill="#5f4d9c"/>
                                <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4" transform="translate(13 6.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4" transform="translate(13 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4" transform="translate(8 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4" transform="translate(3 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4" transform="translate(8 6.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4" transform="translate(3 6.936)" fill="#5f4d9c"/>
                            </svg>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>


                <div class="card-input flex items-center text-sm py-2 w-3/4 mb-3">
                    <div class="flex items-center space-x-3">
                    <span class="text-sm w-100-px">
                        Status:
                    </span>
                        <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="status_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span class="barlow text-black text-base font-lg p-5">All</span>
                            <span class="material-icons violet-text">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #status_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  " >
                                All 
                            </button>
                        </mat-menu>
                    </div>
                </div>
                
            </div>
        </div>
        <div class="w-full flex items-center justify-center mt-10 mb-4">
            <button class="uppercase custom-btn btn-dark-orange font-semibold text-base px-6 py-2">
                submit
            </button>
        </div>
    </div>

    <div class="bg-light-orange w-full maxh-400-px py-4 px-2 overflow-x-auto w-table">
        <div class="w-full flex items-center justify-between space-x-3 pr-3 text-sm">
            <div class="flex items-center" >
                <div class="orange-text">show:</div>
                <input class="orange-input focus:outline-none px-2 py-1 mx-2 w-60-px" type="number"  >
                <div class="orange-text">entries</div>
            </div>
            <div class="flex items-center space-x-3" >
                <div class="orange-text">Search:</div>
                <input class="orange-input focus:outline-none px-2 py-1" type="text" placeHolder="Search">
            </div>
        </div>
        <div class="data-table mt-3 barlow text-xs">
            <table class="w-full">
                <thead>
                    <tr>
                        <th class="text-left barlow">
                            TRANSACTION ID
                        </th>
                        <th class="text-left barlow">
                            Date/TIME
                        </th>
                        <th class="text-left barlow">
                            NAME
                        </th>
                        <th class="text-left barlow">
                            PHONE NUMBER
                        </th>
                        <th class="text-left barlow">
                            RECIPIENT NUMBER
                        </th>
                        <th class="text-left barlow">
                            PRODUCT ID
                        </th>
                        <th class="text-left barlow">
                            ITEM TYPE
                        </th>
                        <th class="text-left barlow">
                            LOAD PROVIDER
                        </th>
                        <th class="text-left barlow">
                            AMOUNT
                        </th>
                        <th class="text-left barlow">
                            STATUS
                        </th>
                        <th class="text-left barlow">
                            TRACE NO.
                        </th>

                        <th class="text-left barlow">
                            ITEM TYPE
                        </th>

                        <th class="text-left barlow">
                            MODIFIED DATE/TIME
                        </th>
                        
                    </tr>
                </thead>
                <tbody>
                    <ng-container >
                        <tr class="focus:outline-none" *ngFor="let item of transaction" >
                            <td>{{ item.id }}</td>
                            <td >{{ item.date | date: 'yyyy-MM-dd hh:mm:ss' }}</td>
                            <td class="capitalize">{{ item.name }}</td>
                            <td >{{ item.phone_number }}</td>
                            <td class="capitalize">{{ item.recipient_number }}</td>
                            <td class="capitalize">{{ item.product_id }}</td>
                            <td class="capitalize">{{ item.item_type }}</td>
                            <td class="capitalize">{{ item.load_provider }}</td>
                            <td class="capitalize">{{ item.amount }}</td>
                            <td class="capitalize">{{ item.status }}</td>
                            <td class="capitalize">{{ item.trace_number }}</td>
                            <td class="capitalize">{{ item.type }}</td>
                            <td >{{ item.date_modified | date: 'yyyy-MM-dd hh:mm:ss' }}</td>
                        </tr>
                    </ng-container>
                    <!-- <ng-container *ngIf="isLoading">
                        <ng-container *ngFor="let item of [1,2,3,4]">
                            <tr>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container> -->
                </tbody>
            </table>
        </div>
        <div class="col-span-full flex justify-between items-center">
            <div class="orange-text text-sm">
                showing 5 of 25 entree
            </div>
          <div class="pr-3 flex items-center justify-end table-pagination mt-6" style="text-align: right;">
            <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;">
                Previous 
            </button> 
            <button class="page-btn sarabun-bold orange-box px-2"  style="white-space: nowrap;" >
                1
            </button> 
            <button class="page-btn sarabun-bold orange-box px-2"  style="white-space: nowrap;" >
                2
                </button> 
                <button class="page-btn sarabun-bold orange-box px-2"  style="white-space: nowrap;" >
                3
                </button> 
        
            <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;">
                ...
            </button>
        
            <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;">
                5
            </button> 
            <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;" >
                Next 
            </button> 
          </div>
        </div>
    </div>
</div>