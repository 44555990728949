import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  show_dropdown: boolean = false
  constructor(
    private route: Router
  ) {

   }

  ngOnInit(): void {
    console.log('url: ', this.route.url)
    if(this.route.url == '/dashboard'){
      localStorage.setItem('navigate', '0');
      
    }
  }
  showDropdown(bool){
    // this.show_dropdown = !this.show_dropdown
    // console.log('asdfasdfasdfa: ', this.show_dropdown)
    if(bool == true){
      this.show_dropdown = !this.show_dropdown
    }else{
      setTimeout(() => {
        this.show_dropdown = false
      }, 300);
    }
  }
  logout(){
    setTimeout(() => {
      localStorage.removeItem('username');
      localStorage.removeItem('password');
      this.route.navigate(['/login'])
    }, 300);
  }
  setheader(index){
    localStorage.setItem('navigate', index);
  }

}
