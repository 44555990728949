<div class="h-full w-full">
    <div class="px-8 py-5">
        <div class="flex items-center space-x-1 text-sm justify-center mb-3 w-900-px mx-auto relative">
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold btn-light-orange">
                MEGAPAY USER ACCOUNTS
            </button>
            <div class="absolute right-0 close-orange flex items-center justify-center">
                <span class="material-icons orange-text text-base cursor-pointer" [routerLink]="['/kyc']" routerLinkActive="router-link-active" >
                    close
                </span>
            </div>
        </div>
        <div class="w-900-px mx-auto">
            <div class="grid grid-cols-12 gap-3">
                <div class="col-span-6">
                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-60-px">
                            Name:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                            type="text" placeholder="Name">
                    </div>

                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-60-px">
                            Email:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                            type="text" placeholder="Email">
                    </div>

                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-165-px">
                            Current Address:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                            type="text" placeholder="Current Address">
                    </div>

                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-70-px">
                            Province:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                            type="text" placeholder="Province">
                    </div>

                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-290-px">
                            Birth Date: (yyyy-mm-dd)
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                            type="text" placeholder="yyyy-mm-dd">
                    </div>

                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-90-px">
                            Nationality:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                            type="text" placeholder="Nationality">
                    </div>

                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-220-px">
                            Employment Status:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                            type="text" placeholder="Employment Status">
                    </div>
                    <div class="flex items-center justify-center w-full mt-1">
                        <div>
                            <div class="flex justify-between mb-1">
                                <div>Selfie Image</div>
                                <div class="flex items-center space-x-1">
                                    <span class="material-icons text-base btn-icon cursor-pointer">
                                        refresh
                                    </span>
                                    <span class="material-icons text-base btn-icon cursor-pointer">
                                        zoom_out
                                    </span>
                                    <span class="material-icons text-base btn-icon cursor-pointer">
                                        zoom_in
                                    </span>
                                </div>
                            </div>
                            <div class="card-input flex items-center text-sm py-2 w-250-px h-250-px mb-3">
    
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-span-6">
                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-140-px">
                            Phone number:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                            type="text" placeholder="Phone number">
                    </div>
                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-100-px">
                            Civil Status:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                            type="text" placeholder="Civil Status">
                    </div>
                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-220-px">
                            Permanent Address:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                            type="text" placeholder="Permanent Address">
                    </div>
                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-45-px">
                            City:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                            type="text" placeholder="City">
                    </div>
                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-100-px">
                            Birth Place:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                            type="text" placeholder="Birth Place">
                    </div>
                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-100-px">
                            Zip Code:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                            type="text" placeholder="Zip Code">
                    </div>
                    <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                        <span class="text-sm w-160-px">
                            Source of Fund:
                        </span>
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                            type="text" placeholder="Source of Fund">
                    </div>

                    <div class="flex items-center justify-center w-full mt-1">
                        <div>
                            <div class="flex justify-between mb-1">
                                <div>ID Image</div>
                                <div class="flex items-center space-x-1 ">
                                    <span class="material-icons text-base btn-icon cursor-pointer">
                                        refresh
                                    </span>
                                    <span class="material-icons text-base btn-icon cursor-pointer">
                                        zoom_out
                                    </span>
                                    <span class="material-icons text-base btn-icon cursor-pointer">
                                        zoom_in
                                    </span>
                                    
                                </div>
                            </div>
                            <div class="card-input flex items-center text-sm py-2 w-250-px h-250-px mb-3">
    
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div class="w-full flex items-center justify-center mt-6 mb-4 space-x-2">
                <button class="uppercase custom-btn btn-dark-orange font-semibold text-base px-6 py-2 focus:outline-none">
                    Approve
                </button>
                <button class="uppercase custom-btn btn-dark-orange font-semibold text-base px-6 py-2 focus:outline-none">
                    disApprove
                </button>
            </div>
        </div>
    </div>
</div>