import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-features-push-notif',
  templateUrl: './features-push-notif.component.html',
  styleUrls: ['./features-push-notif.component.scss']
})
export class FeaturesPushNotifComponent implements OnInit {

  cover_img: any;
  featured_img: any;
  constructor() { }

  ngOnInit(): void {
  }
  onSelectFileCover(event) { // called each time file input changes
      if (event.target.files && event.target.files[0]) {
        var reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]); // read file as data url

        reader.onload = (event) => { // called once readAsDataURL is completed
          this.cover_img = event.target.result;
        }
      }
  }
  onSelectFileFeatured(event) { // called each time file input changes
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url

      reader.onload = (event) => { // called once readAsDataURL is completed
        this.featured_img = event.target.result;
      }
    }
  }

}
