<div class="w-full h-full">
    <div class="w-900-px mx-auto mt-8">
        <div class="grid grid-cols-12 gap-6">
            <div class="col-span-6 space-y-5">
                <div class="card-input flex items-start text-sm py-2 w-full">
                    <span class="text-sm w-300-px">
                        Restrict the following words:
                    </span>
                    <textarea class="placeholder-black focus:outline-none w-full h-90-px bg-gray-100 rounded-md pl-3 resize-none" placeholder="Description (optional)" name="" id="" cols="30" rows="10"></textarea>
                </div>
            </div>
            <div class="col-span-6 space-y-5">
                <div class="card-input flex items-start text-sm py-2 w-full">
                    <span class="text-sm w-300-px">
                        Allow the following words:
                    </span>
                    <textarea class="placeholder-black focus:outline-none w-full h-90-px bg-gray-100 rounded-md pl-3 resize-none" placeholder="Description (optional)" name="" id="" cols="30" rows="10"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="w-full flex items-center justify-center mt-6 mb-4 ">
        <button class="uppercase custom-btn btn-dark-orange font-semibold text-base px-6 py-2">
            Add
        </button>
    </div>
    <div class="w-700-px mx-auto mt-8">
        <div class="text-xl font-semibold">List of prohibited words:</div>
        <ul class="px-7 mt-3">
            <div class="grid grid-cols-3 gap-1">
                <div class="col-span-1" *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]">
                    <li class="text-xs">Words</li>
                </div>
            </div>
        </ul>
    </div>
</div>