
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpClientModule } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Urls } from './urls';
// import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class EnvService {
  /* For Server */
 private secure = true;

    /* For Local */
    // private secure = false;
    private payload: any;

    /* LIVE Production */
    // private domain = 'https://backend.megapay.com.ph:444/megapayportalbackend/api';
    // private url = 'https://backend.megapay.com.ph:444/megapayportalbackend/api';
    // private secureUrl = 'https://backend.megapay.com.ph:444/megapayportalbackend/api';

    /* LIVE Staging */
    private domain = 'development.megapay.com.ph:444/megapayportalbackend/api/';
    private url = 'https://development.megapay.com.ph:444/megapayportalbackend/api/';
    private secureUrl = 'https://development.megapay.com.ph:444/megapayportalbackend/api/';

    


    public token: any = null;

    public production_mode: boolean = false;
    data: string;
    public userData: any;

    constructor(
        public http: HttpClient,
        public httpClient: HttpClientModule,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public location: Location,
        // private toastr: ToastrService
    ) {
        this.data = '';
    }

  

  

  getUserHttpOptions() {
    return new Promise(resolve => {
      let username = JSON.parse(localStorage.getItem('username'));
      let password = JSON.parse(localStorage.getItem('password'));
          let httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/x-www-form-urlencoded',
              'Accept': 'application/json',
              'Authorization': 'Basic ' + btoa(username+':'+password)
            })
          };
          resolve(httpOptions);
    });
  }
  getUrl( path: string, params: any = null):any{
    let url = '';
    if(this.secure === true){
        url += this.secureUrl;
    } else {
        url += this.url;
    }
    url += path;
    if(params){
      url += this.createUrlParam(params)
    }
    return url;
  }

  createUrlParam(p){
    let uriStr = '?';
    for (let key of Object.keys(p)) {
      if(p[key]){
        uriStr += key + '=' + p[key] + '&';
      }
    }
    return uriStr;
  }

  redirect(path: string = '/', navParams?) {
	  if (navParams) {
	    this.router.navigate([path], navParams);
	  }else{
	    this.router.navigate([path]);
	  }
  }

//   getMomentFormat(date, format?, isIos?) {
//     if (format) {
// 			return moment(date).format(format);
// 		}
// 		else {
// 			return moment(date, 'YYYYMMDD+-HH:mm').format();
//     }
//   }

//   getMomentAgo(date) {
//     if (date) {
//       return moment(date, 'YYYYMMDD+-HH:mm').fromNow();
//     }
//     else {
//       return false;
//     }
//   }

  getHttpOptions(){
    let username = JSON.parse(localStorage.getItem('username'));
    let password = JSON.parse(localStorage.getItem('password'));
    let httpOptions = {
        headers: new HttpHeaders({ 
            'Authorization': 'Basic ' + btoa(username+':'+password),
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        })
    };
    return httpOptions;
  }

}