import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-features-profanity-filter',
  templateUrl: './features-profanity-filter.component.html',
  styleUrls: ['./features-profanity-filter.component.scss']
})
export class FeaturesProfanityFilterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
