import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-features-rdpd-dashboard',
  templateUrl: './features-rdpd-dashboard.component.html',
  styleUrls: ['./features-rdpd-dashboard.component.scss']
})
export class FeaturesRdpdDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }
  }

}
