import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-features-biller',
  templateUrl: './features-biller.component.html',
  styleUrls: ['./features-biller.component.scss']
})
export class FeaturesBillerComponent implements OnInit {
  selected_tab: any = 4;
  constructor() { }

  ngOnInit(): void {
  }

  selectTab(tab){
    this.selected_tab = tab
  }

}
