<div class="h-full w-full">
    <div class="px-8 py-5">
        <!-- tabs -->
        <div class="flex items-center space-x-1 text-sm justify-start mb-3">
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase w-100-px font-semibold {{ selected_tab == 1 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(1)">
                SCREEN
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 w-100-px uppercase font-semibold {{ selected_tab == 2 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(2)">
                LINK
            </button>
        </div>
    </div>
    <!-- rendered tab -->
    <div class="w-full h-full">
        <ng-container *ngIf="selected_tab == 1">
            <app-ads-dashboard-screen></app-ads-dashboard-screen>
        </ng-container>
        <ng-container *ngIf="selected_tab == 2">
            <app-ads-dashboard-link></app-ads-dashboard-link>
        </ng-container>
    </div>
</div>