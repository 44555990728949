<div class="w-full h-full py-8">
    <form [formGroup]="ads_screen_form">
        <ng-container *ngIf="ads_details">
            <div class="px-8">
                <div class="grid grid-cols-12 gap-3">
                    <div class="col-span-8 space-y-5">
                        <div class="card-input flex items-center text-sm py-2 w-full">
                            <span class="text-sm w-110-px">
                                Title:
                            </span>
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" formControlName="title" type="text" placeholder="Title" value="{{ads_details.title}}">
                        </div>
        
                        <div class="card-input flex items-start text-sm py-2 w-full">
                            <span class="text-sm w-110-px">
                                Details:
                            </span>
                            <textarea class="placeholder-black focus:outline-none w-full h-70-px bg-gray-100 rounded-md pl-3 resize-none" formControlName="description" placeholder="Enter Details" name="" id="" cols="30" rows="10" >test</textarea>
                        </div>
                        <div class="flex flex-col items-start text-sm pl-5 mb-3">
                            <div class="flex items-center space-x-3">
                                <span>Image: </span>
                                <input type='file' class="hidden" (change)="onSelectFile($event)" #featuredImg>
                                <button class="browse-btn px-4 py-2 focus:outline-none" (click)="featuredImg.click()">
                                    Browse...
                                </button>
                            </div>
                            <img [src]="featured_img" class="w-full h-200-px object-contain" *ngIf="featured_img"> <br/>
                        </div>
                    </div>
                    <div class="col-span-4">
                        <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                            <span class="text-sm w-106-px">
                                Date From:
                            </span>
                            <!-- <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy"> -->
                            <mat-form-field color="accent" appearance="">
                                <input
                                    formControlName="dateFrom"
                                    class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                                    type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker1" readonly
                                    (click)="startDate.click()" value="{{ads_details.dateFrom}}">
                                <mat-datepicker-toggle matSuffix [for]="picker1" class="focus:outline-none">
                                    <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                        id="iconfinder_calendar-book-date-monthly_2203551"
                                        xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                        <path id="Path_371" data-name="Path 371"
                                            d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                            fill="#5f4d9c" />
                                        <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                            transform="translate(13 6.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                            transform="translate(13 11.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                            transform="translate(8 11.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                            transform="translate(3 11.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                            transform="translate(8 6.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                            transform="translate(3 6.936)" fill="#5f4d9c" />
                                    </svg>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                            <span class="text-sm w-106-px">
                                Date To:
                            </span>
                            <mat-form-field color="accent" appearance="">
                                <input
                                    formControlName="dateTo"
                                    class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3"
                                    type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker2" readonly
                                    (click)="startDate.click()" value="{{ads_details.dateTo}}">
                                <mat-datepicker-toggle matSuffix [for]="picker2" class="focus:outline-none">
                                    <svg #startDate class="focus:outline-none" matDatepickerToggleIcon
                                        id="iconfinder_calendar-book-date-monthly_2203551"
                                        xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                        <path id="Path_371" data-name="Path 371"
                                            d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z"
                                            fill="#5f4d9c" />
                                        <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4"
                                            transform="translate(13 6.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4"
                                            transform="translate(13 11.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4"
                                            transform="translate(8 11.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4"
                                            transform="translate(3 11.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4"
                                            transform="translate(8 6.936)" fill="#5f4d9c" />
                                        <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4"
                                            transform="translate(3 6.936)" fill="#5f4d9c" />
                                    </svg>
                                </mat-datepicker-toggle>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
        
                        <div class="card-input flex items-center text-sm py-2 w-full mb-3 mt-3">
                            <div class="flex items-center space-x-3">
                            <span class="text-sm w-120-px">
                                Redirect to:
                            </span>
                                <div #t3="matMenuTrigger" #select3 [matMenuTriggerFor]="network_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                                    <span *ngIf="!screen_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                                    <span *ngIf="screen_text" class="barlow text-black text-xs font-lg p-5 italic">{{screen_text}}</span>
                                    <span class="material-icons violet-text mr-1">
                                        arrow_drop_down
                                    </span>
                                </div>
                                <mat-menu class="w-full sports-menu" #network_select="matMenu">
                                    <button mat-menu-item class="tracking-wide focus:outline-none  italic" >
                                        == Select an option ==
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setScreen('Cash-in','1')">
                                        Cash-in
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setScreen('Buy Load','7')">
                                        Buy Load
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setScreen('Paybills','8')">
                                        Paybills
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setScreen('Redeem','10')">
                                        Redeem
                                    </button>
                                    <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setScreen('Purchase','11')">
                                        Purchase
                                    </button>
                                </mat-menu>
                            </div>
                        </div>
        
                        <!-- <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                            <span class="text-sm w-110-px">
                                Link:
                            </span>
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="Enter URL">
                        </div> -->
                        <div class="card-input flex items-center text-sm py-2 w-full">
                            <span class="text-sm w-280-px">
                                Duration (In Seconds):
                            </span>
                            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" formControlName="duration" type="text" placeholder="Duration" value="{{ads_details.duration}}">
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
    </form>
    

    <div class="w-full flex items-center justify-center mt-6 mb-4">
        <button class="uppercase custom-btn btn-dark-orange font-semibold text-base px-6 py-2" (click)="update()">
            Update
        </button>
    </div>
    
</div>
<ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "#fff" type = "timer" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner>