<div class="w-full h-full p-5">
    <div class="grid grid-cols-12 gap-3">
        <div class="col-span-4">
            <!-- daily overview -->
            <div class="dashboard-card shadow-sm mb-3">
                <div class="flex items-center space-x-3 mb-2">
                    <img src="assets/images/icon/daily.svg" class="w-30-px h-full object-contain" alt="">
                    <span class="text-sm font-semibold">
                        DAILY OVERVIEW
                    </span>
                </div>
                <ng-container *ngFor="let item of daily_overview">
                    <div class="grid grid-cols-12 mb-2">
                        <div class="col-span-8">
                            <div class="b-violet px-1 text-sm uppercase orange-text">
                                {{ item.title }}
                            </div>
                        </div>
                        <div class="col-span-4">
                            <div class="border-gray-400 border bg-gray-100 text-sm px-1">
                                {{ item.value }}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- pengding validation approval -->
            <div class="dashboard-card shadow-sm mb-3">
                <div class="flex items-center space-x-3 mb-2">
                    <img src="assets/images/icon/pending.svg" class="w-30-px h-full object-contain" alt="">
                    <span class="fs-13-px font-semibold">
                        PENDING FOR VALIDATION AND APPROVAL
                    </span>
                </div>
                <div class="grid grid-cols-12 mb-1 gap-2">
                    <div class="col-span-6"></div>
                    <div class="col-span-6">
                        <div class="flex items-center justify-between space-x-1">
                            <div class="text-sm w-full text-center">
                                Validation
                            </div>
                            <div class="text-sm w-full text-center">
                                approval
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngFor="let item of pending">
                    <div class="grid grid-cols-12 mb-2 gap-2">
                        <div class="col-span-6">
                            <div class="b-violet px-1 text-sm uppercase orange-text">
                                {{ item.title }}
                            </div>
                        </div>
                        <div class="col-span-6">
                            <div class="flex items-center justify-between space-x-1">
                                <div class="border-gray-400 border bg-gray-100 text-sm px-1 w-full">
                                    {{ item.validation }}
                                </div>
                                <div class="border-gray-400 border bg-gray-100 text-sm px-1 w-full">
                                    {{ item.approval }}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- megapay -->
            <div class="dashboard-card shadow-sm mb-3">
                <div class="flex items-center space-x-3 mb-2">
                    <img src="assets/images/icon/megapay.svg" class="w-30-px h-full object-contain" alt="">
                    <span class="text-sm font-semibold uppercase">
                        megapay
                    </span>
                </div>
                <ng-container *ngFor="let item of megapay">
                    <div class="grid grid-cols-12 mb-2">
                        <div class="col-span-8">
                            <div class="b-violet px-1 text-sm uppercase orange-text">
                                {{ item.title }}
                            </div>
                        </div>
                        <div class="col-span-4">
                            <div class="border-gray-400 border bg-gray-100 text-sm px-1">
                                {{ item.value }}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="col-span-8">
            <div class="grid grid-cols-8 gap-3">
                <div class="col-span-4">
                    <!-- megapay wallet monitoring -->
                    <div class="dashboard-card shadow-sm mb-3">
                        <div class="flex items-center space-x-3 mb-2">
                            <img src="assets/images/icon/wallet.svg" class="w-30-px h-full object-contain" alt="">
                            <span class="text-sm font-semibold uppercase">
                                megapay wallet monitoring
                            </span>
                        </div>
                        <ng-container *ngFor="let item of mg_wallet_monitoring">
                            <div class="grid grid-cols-12 mb-2">
                                <div class="col-span-8">
                                    <div class="b-violet px-1 text-sm uppercase orange-text">
                                        {{ item.title }}
                                    </div>
                                </div>
                                <div class="col-span-4">
                                    <div class="border-gray-400 border bg-gray-100 text-sm px-1">
                                        {{ item.value }}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    
                </div>
                <div class="col-span-4">
                    <!-- partner wallet monitoring -->
                    <div class="dashboard-card shadow-sm mb-3">
                        <div class="flex items-center space-x-3 mb-2">
                            <img src="assets/images/icon/partner_wallet.svg" class="w-30-px h-full object-contain" alt="">
                            <span class="text-sm font-semibold uppercase">
                                partner wallet monitoring
                            </span>
                        </div>
                        <ng-container *ngFor="let item of partner_wallet_monitoring">
                            <div class="grid grid-cols-12 mb-2">
                                <div class="col-span-8">
                                    <div class="b-violet pl-1 fs-13-px uppercase orange-text flex">
                                        {{ item.title }}
                                    </div>
                                    <div class="text-right text-xs w-full pr-2">
                                        Days of inventory:
                                    </div>
                                </div>
                                <div class="col-span-4">
                                    <div class="border-gray-400 border bg-gray-100 fs-13-px px-1">
                                        {{ item.value }}
                                    </div>
                                    <div class="underline text-xs w-full">
                                        {{ item.doi}}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="col-span-8">
                    <!-- suspicious transaction -->
                    <div class="dashboard-card shadow-sm mb-3">
                        <div class="flex items-center space-x-3 mb-2">
                            <img src="assets/images/icon/faq.svg" class="w-30-px h-full object-contain" alt="">
                            <span class="text-sm font-semibold">
                                DAILY OVERVIEW
                            </span>
                        </div>

                        <div class="grid grid-cols-12 mb-1 gap-3">
                            <div class="col-span-3">
                                <div class="text-xs pl-3">
                                    Name
                                </div>
                            </div>
                            <div class="col-span-6">
                                <div class="text-xs pl-3 capitalize">
                                    suspicoious transaction
                                </div>
                            </div>
                            <div class="col-span-3">
                                <div class="text-xs pl-3 capitalize">
                                    date
                                </div>
                            </div>
                        </div>
                        <ng-container *ngFor="let item of suspicious_transaction">
                            <div class="grid grid-cols-12 mb-2 gap-3">
                                <div class="col-span-3 pr-2">
                                    <div class="b-violet px-1 text-sm uppercase orange-text">
                                        {{ item.name }}
                                    </div>
                                </div>
                                <div class="col-span-6">
                                    <div class="border-gray-400 border bg-gray-100 text-sm px-1">
                                        {{ item.transaction }}
                                    </div>
                                </div>
                                <div class="col-span-3">
                                    <div class="border-gray-400 border bg-gray-100 text-sm px-1">
                                        {{ item.date | date: 'MM/dd/yyyy' }}
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>