import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss']
})
export class TransactionsComponent implements OnInit {
  selected_tab: any = 1;
  constructor() { }

  ngOnInit(): void {
  }
  selectTab(tab){
    this.selected_tab = tab
  }

}
