<div class="grid grid-cols-12 gap-3">
    <div class="col-span-12">
        <div class="text-xl text-black font-semibold uppercase text-center">
            Change Name
        </div>
    </div>
    <div class="col-span-4">
        <div class="card-input flex items-center text-sm py-2 w-full mb-3">
            <span class="text-sm w-110-px">
                First Name:
            </span>
            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="First Name">
        </div>
    </div>
    <div class="col-span-4">
        <div class="card-input flex items-center text-sm py-2 w-full mb-3">
            <span class="text-sm w-150-px">
                Middle Name:
            </span>
            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="Middle Name">
        </div>
    </div>
    <div class="col-span-4">
        <div class="card-input flex items-center text-sm py-2 w-full mb-3">
            <span class="text-sm w-120-px">
                Last Name:
            </span>
            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="Last Name">
        </div>
    </div>

    <div class="col-span-12">
        <hr class="border border-gray-300 w-full my-1 mb-3">
    </div>

    <div class="col-span-12">
        <div class="text-xl text-black font-semibold uppercase text-center">
            Change Number
        </div>
    </div>
    <div class="col-span-6">
        <div class="card-input flex items-center text-sm py-2 w-full mb-3">
            <span class="text-sm w-150-px">
                Current Number:
            </span>
            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="Current Number">
        </div>
    </div>
    <div class="col-span-6">
        <div class="card-input flex items-center text-sm py-2 w-full mb-3">
            <span class="text-sm w-150-px">
                New Number:
            </span>
            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="New Number">
        </div>
    </div>
    <div class="col-span-12">
        <hr class="border border-gray-300 w-full my-1 mb-3">
    </div>

    <div class="col-span-12">
        <div class="text-xl text-black font-semibold uppercase text-center">
            Change Email
        </div>
    </div>
    <div class="col-span-6">
        <div class="card-input flex items-center text-sm py-2 w-full mb-3">
            <span class="text-sm w-150-px">
                Current Email:
            </span>
            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="Current Email">
        </div>
    </div>
    <div class="col-span-6">
        <div class="card-input flex items-center text-sm py-2 w-full mb-3">
            <span class="text-sm w-120-px">
                New Email:
            </span>
            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="                New Email:
            ">
        </div>
    </div>
    <div class="col-span-12">
        <hr class="border border-gray-300 w-full my-1 mb-3">
    </div>

    <div class="col-span-12">
        <div class="text-xl text-black font-semibold uppercase text-center">
            Change Tier
        </div>
    </div>
    <div class="col-span-6">
        <div class="card-input flex items-center text-sm py-2 w-full mb-3">
            <span class="text-sm w-150-px">
                Current Tier:
            </span>
            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="Current Tier">
        </div>
    </div>
    <div class="col-span-6">
        <div class="card-input flex items-center text-sm py-2 w-3/4 mb-3">
            <div class="flex items-center space-x-3">
            <span class="text-sm w-100-px">
                Tier Type:
            </span>
                <div #t1="matMenuTrigger" #select1 [matMenuTriggerFor]="type_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                    <span class="barlow text-black text-base font-lg p-5">All</span>
                    <span class="material-icons violet-text">
                        arrow_drop_down
                    </span>
                </div>
                <mat-menu class="w-full sports-menu" #type_select="matMenu">
                    <button mat-menu-item class="tracking-wide focus:outline-none  " >
                        All 
                    </button>
                </mat-menu>
            </div>
        </div>
    </div><div class="col-span-12">
        <hr class="border border-gray-300 w-full my-1 mb-3">
    </div>

    <div class="col-span-12">
        <div class="text-xl text-black font-semibold uppercase text-center">
            Change Account Type
        </div>
    </div>
    <div class="col-span-6">
        <div class="card-input flex items-center text-sm py-2 w-full mb-3">
            <span class="text-sm w-220-px">
                Current Account Type:
            </span>
            <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="Current Account Type:">
        </div>
    </div>
    <div class="col-span-6">
        <div class="card-input flex items-center text-sm py-2 w-3/4 mb-3">
            <div class="flex items-center space-x-3">
            <span class="text-sm w-100-px">
                Account Type:
            </span>
                <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="acc_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                    <span class="barlow text-black text-base font-lg p-5">All</span>
                    <span class="material-icons violet-text">
                        arrow_drop_down
                    </span>
                </div>
                <mat-menu class="w-full sports-menu" #acc_select="matMenu">
                    <button mat-menu-item class="tracking-wide focus:outline-none  " >
                        All 
                    </button>
                </mat-menu>
            </div>
        </div>
    </div>
    <div class="col-span-12">
        <div class="w-full flex items-center justify-center mt-10 mb-4">
            <button class="uppercase custom-btn btn-dark-orange font-semibold text-base px-6 py-2">
                submit
            </button>
        </div>
    </div>
</div>