import { Component, OnInit } from '@angular/core';
import { NgxSmartModalComponent, NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-confirmation_modal',
  templateUrl: './confirmation_modal.component.html',
  styleUrls: ['./confirmation_modal.component.scss']
})
export class ModalComponent implements OnInit {
  confirmation_detail: any;
  isLoading:boolean = false;;

  constructor(
    private modal: NgxSmartModalService
  ) { }

  ngOnInit(): void {
  }

  getConfirmDetail(){
    this.confirmation_detail = this.modal.getModalData('confirmationModal');
  }

  reset(){
    this.modal.resetModalData('confirmationModal')
    this.modal.close('confirmationModal');
    this.confirmation_detail = []
  }
  main_action(){
    this.modal.close('confirmationModal');
    this.confirmation_detail = []
  }
 
}
