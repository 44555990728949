import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kyc-form',
  templateUrl: './kyc-form.component.html',
  styleUrls: ['./kyc-form.component.scss']
})
export class KycFormComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
