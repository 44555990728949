<div class="w-full h-full">
    <div class="login-bg h-70-px flex items-center px-4 space-x-2 relative">
        <div>
            <img src="assets/images/default_avatar.png" class="w-35-px h-35-px rounded-full object-contain" alt="">
        </div>
        <div class="flex items-center w-full">
            <div class="flex flex-col uppercase text-white">
                <span class="fs-12-px font-bold">FERDINAND BUENSALIDa</span>
                <span class="fs-10-px">super admin</span>
            </div>
            <button class="focus:outline-none ml-auto" (focus)="showDropdown(true)" (blur)="showDropdown(false)">
                <span class="material-icons text-white cursor-pointer" >
                    arrow_drop_down
                </span>
            </button>
        </div>
        <div class="dropdown-list fade" *ngIf="show_dropdown">
            <div class="flex items-center cursor-pointer list purple px-8 py-3" [routerLink]="['/change-pass']">
                <div class="side-icon-bg-white flex items-center justify-center">
                    <span class="material-icons violet-text text-base">
                        lock
                    </span>
                </div>
                <div class="text-white text-sm ml-3">
                    CHANGE PASSWORD
                </div>
            </div>
            <div class="flex items-center cursor-pointer list purple px-8 py-3" (click)="logout()">
                <div class="side-icon-bg-white flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 16.348 16.363">
                        <path id="iconfinder_UI_Essentials_exit_door_6646407" d="M19.3,8.779c-.058-.058-.058-.117-.117-.175L16.85,6.266a.579.579,0,0,0-.818.818l1.344,1.344H14.1V2.584A.552.552,0,0,0,13.519,2H3.584a.351.351,0,0,0-.234.058H3.292a.057.057,0,0,0-.058.058l-.058.058h0c-.058.058-.117.117-.117.175v.058A.215.215,0,0,0,3,2.584V15.441a.511.511,0,0,0,.351.526L8.61,18.3a.351.351,0,0,0,.234.058.446.446,0,0,0,.292-.117.6.6,0,0,0,.292-.468V16.025h4.091a.552.552,0,0,0,.584-.584V9.6h3.273l-1.344,1.344a.565.565,0,0,0,0,.818.565.565,0,0,0,.818,0l2.338-2.338c.058-.058.117-.117.117-.175A.645.645,0,0,0,19.3,8.779ZM7.091,13.1a.552.552,0,0,1-.584.584.552.552,0,0,1-.584-.584V10.766a.552.552,0,0,1,.584-.584.552.552,0,0,1,.584.584Zm5.844-6.428v8.182H9.428V4.922A.511.511,0,0,0,9.078,4.4L6.331,3.169h6.6Z" transform="translate(-3 -2)" fill="#5f4d9c"/>
                    </svg>
                </div>
                <div class="text-white text-sm ml-3">
                    LOGOUT
                </div>
            </div>
            <div class="flex items-center cursor-pointer list purple px-8 py-3">
                <div class="side-icon-bg-white flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12.5" height="15.909" viewBox="0 0 12.5 15.909">
                        <path id="login_account_enter_door" d="M17.5,2.568V17.341a.568.568,0,0,1-.568.568H5.568A.568.568,0,0,1,5,17.341V16.2H6.136v.568H16.364V3.136H6.136v1.7H5V2.568A.568.568,0,0,1,5.568,2H16.932A.568.568,0,0,1,17.5,2.568ZM9.142,12.392l.807.807,2.841-2.841a.568.568,0,0,0,0-.807L9.949,6.71l-.807.807,1.875,1.869H5v1.136h6.017Z" transform="translate(-5 -2)" fill="#5f4d9c"/>
                    </svg>
                </div>
                <div class="text-white text-sm ml-3">
                    LAST LOGIN
                </div>
            </div>
        </div>
    </div>
    <div class="bg-lavender h-full">
        <div class="flex items-center cursor-pointer list px-4 py-3" (click)="setheader(0)" [routerLink]="['/dashboard']" routerLinkActive="route-active" >
            <div class="side-icon-bg flex items-center justify-center">
                <span class="material-icons text-white text-base">
                    dashboard
                </span>
            </div>
            <div class="violet-text text-base ml-3">
                DASHBOARD
            </div>
        </div>
        <div class="flex items-center cursor-pointer list px-4 py-3" (click)="setheader(1)" [routerLink]="['/transactions']" routerLinkActive="route-active" >
            <div class="side-icon-bg flex items-center justify-center">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 19.999 16.363">
                    <path id="Path_711" data-name="Path 711" d="M135.11,146.372a1.212,1.212,0,1,0,0-2.424H121.674l1.564-1.57a1.212,1.212,0,0,0-1.709-1.709l-3.636,3.636a1.208,1.208,0,0,0,.855,2.067Z" transform="translate(-117.534 -140.378)" fill="#fff"/>
                    <path id="Path_361" data-name="Path 361" d="M149.806,264.461H133.443a1.212,1.212,0,1,0,0,2.424h13.436l-1.564,1.57a1.212,1.212,0,1,0,1.709,1.709l3.636-3.636a1.208,1.208,0,0,0-.855-2.067Z" transform="translate(-131.019 -254.225)" fill="#fff"/>
                </svg>
            </div>
            <div class="violet-text text-base ml-3">
                TRANSACTIONS
            </div>
        </div>
        <div class="flex items-center cursor-pointer list px-4 py-3" (click)="setheader(2)" [routerLink]="['/accounts']" routerLinkActive="route-active" >
            <div class="side-icon-bg flex items-center justify-center">
                <span class="material-icons text-white text-lg">
                    supervisor_account
                </span>
            </div>
            <div class="violet-text text-base ml-3">
                ACCOUNTS
            </div>
        </div>
        <div class="flex items-center cursor-pointer list px-4 py-3" (click)="setheader(3)" [routerLink]="['/features/announcement']" routerLinkActive="route-active">
            <div class="side-icon-bg flex items-center justify-center">
                <span class="material-icons text-white text-lg">
                    supervisor_account
                </span>
            </div>
            <div class="violet-text text-base ml-3">
                MEGAPAY FEATURES
            </div>
        </div>
        <div class="flex items-center cursor-pointer list px-4 py-3" (click)="setheader(4)" [routerLink]="['/kyc']" routerLinkActive="route-active" >
            <div class="side-icon-bg flex items-center justify-center">
                <span class="material-icons text-white text-lg">
                    supervisor_account
                </span>
            </div>
            <div class="violet-text text-base ml-3">
                KYC
            </div>
        </div>
        <div class="flex items-center cursor-pointer list px-4 py-3" (click)="setheader(5)" [routerLink]="['/cash-in-out']" routerLinkActive="route-active" >
            <div class="side-icon-bg flex items-center justify-center">
                <span class="material-icons text-white text-lg">
                    supervisor_account
                </span>
            </div>
            <div class="violet-text text-base ml-3">
                CASH IN / OUT
            </div>
        </div>
        <div class="flex items-center cursor-pointer list px-4 py-3" (click)="setheader(6)" [routerLink]="['/admin/third-party-log']" routerLinkActive="route-active" >
            <div class="side-icon-bg flex items-center justify-center">
                <span class="material-icons text-white text-lg">
                    supervisor_account
                </span>
            </div>
            <div class="violet-text text-base ml-3">
                ADMIN
            </div>
        </div>
        <div class="flex items-center cursor-pointer list px-4 py-3" (click)="setheader(7)" >
            <div class="side-icon-bg flex items-center justify-center">
                <span class="material-icons text-white text-lg">
                    supervisor_account
                </span>
            </div>
            <div class="violet-text text-base ml-3">
                REPORTS
            </div>
        </div>
    </div>

</div>