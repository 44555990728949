<div class="w-full h-full">
    <div class="w-900-px mx-auto">
        <div class="grid grid-cols-8 gap-3">
            <div class="col-span-4">
                <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                    <span class="text-sm w-60-px">
                        Name:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="User Name">
                </div>
                <div class="card-input flex items-center text-sm py-2 w-3/4 mb-3">
                    <span class="text-sm w-106-px">
                        Date From:
                    </span>
                    <!-- <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy"> -->
                    <mat-form-field color="accent" appearance="">
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker1" readonly (click)="startDate.click()" >
                        <mat-datepicker-toggle matSuffix [for]="picker1" class="focus:outline-none" >
                            <svg #startDate class="focus:outline-none" matDatepickerToggleIcon id="iconfinder_calendar-book-date-monthly_2203551" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                <path id="Path_371" data-name="Path 371" d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z" fill="#5f4d9c"/>
                                <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4" transform="translate(13 6.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4" transform="translate(13 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4" transform="translate(8 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4" transform="translate(3 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4" transform="translate(8 6.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4" transform="translate(3 6.936)" fill="#5f4d9c"/>
                            </svg>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            <div class="col-span-4">
                <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                    <span class="text-sm w-141-px">
                        Phone Number:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="Phone Number">
                </div>
                <div class="card-input flex items-center text-sm py-2 w-3/4 mb-3">
                    <span class="text-sm w-106-px">
                        Date To:
                    </span>
                    <mat-form-field color="accent" appearance="">
                        <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" type="text" placeholder="dd/mm/yy" matInput [matDatepicker]="picker2" readonly (click)="startDate.click()" >
                        <mat-datepicker-toggle matSuffix [for]="picker2" class="focus:outline-none" >
                            <svg #startDate class="focus:outline-none" matDatepickerToggleIcon id="iconfinder_calendar-book-date-monthly_2203551" xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
                                <path id="Path_371" data-name="Path 371" d="M16.625,0H14.25V1.188H4.75V0H2.375V1.188H0V19H19V1.188H16.625Zm1.188,5.938V17.813H1.188V5.938Z" fill="#5f4d9c"/>
                                <rect id="Rectangle_630" data-name="Rectangle 630" width="4" height="4" transform="translate(13 6.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_631" data-name="Rectangle 631" width="4" height="4" transform="translate(13 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_632" data-name="Rectangle 632" width="4" height="4" transform="translate(8 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_633" data-name="Rectangle 633" width="4" height="4" transform="translate(3 11.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_634" data-name="Rectangle 634" width="4" height="4" transform="translate(8 6.936)" fill="#5f4d9c"/>
                                <rect id="Rectangle_635" data-name="Rectangle 635" width="4" height="4" transform="translate(3 6.936)" fill="#5f4d9c"/>
                            </svg>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                    </mat-form-field>
                </div>
                
            </div>
        </div>
        <div class="w-full flex items-center justify-center mt-10 mb-4">
            <button class="uppercase custom-btn btn-dark-orange font-semibold text-base px-6 py-2">
                submit
            </button>
        </div>
    </div>
    <div class="bg-light-orange w-full maxh-400-px py-4 px-2 overflow-x-auto w-table">
        <div class="w-full flex items-center justify-end space-x-3 pr-3 text-sm">
            <div class="orange-text">Search:</div>
            <input class="orange-input focus:outline-none px-2 py-1" type="text">
        </div>
        <div class="data-table mt-3 barlow text-xs">
            <table class="w-full">
                <thead>
                    <tr>
                        <th class="text-left barlow">
                            Date/TIME
                        </th>
                        <th class="text-left barlow">
                            CASH IN 
                        </th>
                        <th class="text-left barlow">
                            SCAN QR
                        </th>
                        <th class="text-left barlow">
                            TRANSFER FUNDS
                        </th>
                        <th class="text-left barlow">
                            RECEIVE MONEY
                        </th>
                        <th class="text-left barlow">
                            BUY LOAD
                        </th>
                        <th class="text-left barlow">
                            PAY BILLS
                        </th>
                        <th class="text-left barlow">
                            BUY LOAD CASH BACK
                        </th>
                        <th class="text-left barlow">
                            BALANCE OF ALL USERS
                        </th>
                        <th class="text-left barlow">
                            CURRENT/TOTAL USERS
                        </th>
                        <th class="text-left barlow">
                            ANDRIOD/IOS
                        </th>
                        <th class="text-left barlow">
                            NO.OF TRANSACTIONS
                        </th>
                    
                    </tr>
                </thead>
                <tbody>
                    <ng-container >
                        <tr class="focus:outline-none" *ngFor="let item of transaction" >
                            <td >{{ item.date | date: 'yyyy-MM-dd' }}</td>
                            <td class="capitalize">{{ item.name }}</td>
                            <td >{{ item.phone_number }}</td>
                            <td class="capitalize">{{ item.acc_number }}</td>
                            <td class="capitalize">{{ item.acc_name }}</td>
                            <td class="capitalize">{{ item.biller_name }}</td>
                            <td class="capitalize">{{ item.category }}</td>
                            <td class="capitalize">{{ item.amount_due }}</td>
                            <td >{{ item.conv_fee}}</td>
                            <td >{{ item.amount }}</td>
                            <td >{{ item.amount }}</td>
                            <td >{{ item.amount }}</td>
                        </tr>
                    </ng-container>
                    <!-- <ng-container *ngIf="isLoading">
                        <ng-container *ngFor="let item of [1,2,3,4]">
                            <tr>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container> -->
                </tbody>
            </table>
        </div>
    </div>
</div>