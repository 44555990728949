import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login/login.component';
import { TemplateComponent } from './layout/template/template.component';
import { ChangePassComponent } from './component/change-pass/change-pass.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { TransactionsComponent } from './component/transactions/transactions.component';
import { AccountsComponent } from './component/accounts/accounts.component';
import { AccountViewComponent } from './component/account-view/account-view.component';
import { KycComponent } from './component/kyc/kyc.component';
import { MegapayFeaturesComponent } from './component/megapay-features/megapay-features.component';
import { FeaturesBillerComponent } from './component/features-biller/features-biller.component';
import { FeaturesPushNotifComponent } from './component/features-push-notif/features-push-notif.component';
import { FeaturesAnnouncementComponent } from './component/features-announcement/features-announcement.component';
import { AdsDashboardComponent } from './component/ads-dashboard/ads-dashboard.component';
import { FeaturesProfanityFilterComponent } from './component/features-profanity-filter/features-profanity-filter.component';
import { FeaturesAutoDisableLogsComponent } from './component/features-auto-disable-logs/features-auto-disable-logs.component';
import { FeaturesRdpdDashboardComponent } from './component/features-rdpd-dashboard/features-rdpd-dashboard.component';
import { FeaturesReferalComponent } from './component/features-referal/features-referal.component';
import { CashinOutComponent } from './component/cashin-out/cashin-out.component';
import { AdminComponent } from './component/admin/admin.component';
import { AdminTpLogsComponent } from './component/admin-tp-logs/admin-tp-logs.component';
import { AdminSystemMaintenanceComponent } from './component/admin-system-maintenance/admin-system-maintenance.component';
import { KycFormComponent } from './component/kyc-form/kyc-form.component';
import { AuthGuard } from './auth.guard';
import { EditAdsScreenComponent } from './component/edit-ads-screen/edit-ads-screen.component';
import { EditAdsLinkComponent } from './component/edit-ads-link/edit-ads-link.component';



const routes: Routes = [
  { path: 'login', component: LoginComponent},
  { path: '', component: TemplateComponent, canActivate:[AuthGuard],
    children: [
      { path: '',
        children: [
          { path: '', pathMatch: 'full', redirectTo: 'dashboard'},
          { path: 'dashboard', component: DashboardComponent },
          { path: 'transactions', component: TransactionsComponent },
          { path: 'accounts', component: AccountsComponent },
          { path: 'accounts/view', component: AccountViewComponent },
          { path: 'kyc', component: KycComponent },
          { path: 'kyc/form', component: KycFormComponent },
          { path: 'features', component: MegapayFeaturesComponent, children:[
          { path: 'features', pathMatch: 'full', redirectTo: 'biller-dashboard'},
          { path: 'biller-dashboard', component: FeaturesBillerComponent },
          { path: 'push-notification', component: FeaturesPushNotifComponent },
          { path: 'announcement', component: FeaturesAnnouncementComponent },
          { path: 'ads-dashboard', component: AdsDashboardComponent },
          { path: 'edit-ads-screen/:id', component: EditAdsScreenComponent },
          { path: 'edit-ads-link/:id', component: EditAdsLinkComponent },
          { path: 'profanity-filter', component: FeaturesProfanityFilterComponent },
          { path: 'disable-logs', component: FeaturesAutoDisableLogsComponent },
          { path: 'rd-pd-dashboard', component: FeaturesRdpdDashboardComponent },
          { path: 'referrals', component: FeaturesReferalComponent },
        ]},
          { path: 'cash-in-out', component: CashinOutComponent },
          { path: 'admin', component: AdminComponent, children:[
            { path: 'admin', pathMatch: 'full', redirectTo: 'third-party-log'},
            { path: 'third-party-log', component: AdminTpLogsComponent },
            { path: 'system-maintenance', component: AdminSystemMaintenanceComponent },
          ]},
        ]
      }
    ]
  },
  { path: 'change-pass', component: ChangePassComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
