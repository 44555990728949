import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-system-maintenance',
  templateUrl: './admin-system-maintenance.component.html',
  styleUrls: ['./admin-system-maintenance.component.scss']
})
export class AdminSystemMaintenanceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
