export const Urls = {
  ads: '/ads',
  store_ads: '/ads/new',
  announcements: '/announcements',
  store_announcement: '/announcements/new',
  providers:'/buyload/providers',
  buyload:'/buyload/items',
  store_buyload:'/buyload/items/new',
  billers:'/billers',
  store_billers:'/billers/new'
}   