import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { DebounceService } from 'src/app/services/debounce.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-sku-stores',
  templateUrl: './sku-stores.component.html',
  styleUrls: ['./sku-stores.component.scss']
})
export class SkuStoresComponent implements OnInit {
//   public storeItemsForm: FormGroup;
//   isLoading: boolean;
//   stores_list:any;
//   lastPage:any;
//   selected_redirection:string | Blob;

//   // category
//   category_text:any;
//   category:any;

//   //pagination
//   page=1;
//   size=10;
//   total_pages:any;
//   current_page = 1;
//   pages_array=[
//   ];
//   total:any;

//   //search
//   searchKey: any=null;
//   searchForm : FormGroup;
//   search_keyword='';

//   //limit
//   limitKey:any=null;
//   limitForm : FormGroup;


//   constructor( 
//     private modal: NgxSmartModalService,
//     private fb : FormBuilder,
//     public debounce: DebounceService,
//     private request: RequestsService,
//     private toastr: ToastrService,
//     private spinner: NgxSpinnerService,
//     ) {
//       this.initializeForm()
//     }

//   ngOnInit(): void {
//     this.get_stores_list();
//   }
//   initializeForm(){
//     this.storeItemsForm = this.fb.group({
//       productName: [
//         '',
//         Validators.compose([
//           Validators.required
//         ])
//       ],
//       productCode: [
//         '',
//         Validators.compose([
//           Validators.required
//         ])
//       ],
//       productDesc: [
//         '',
//         Validators.compose([
//           Validators.required
//         ])
//       ],
//       productPrice: [
//         '',
//         Validators.compose([
//           Validators.required
//         ]) 
//       ],
//       salesStartDate: [
//         '',
//         Validators.compose([
//           Validators.required
//         ])
//       ],
//       salesEndDate: [
//         '',
//         Validators.compose([
//           Validators.required
//         ])
//       ], 
//       goodsImgUrl: [
//         '',
//         Validators.compose([
//           Validators.required
//         ])
//       ],
//     });
//     this.searchKey = this.debounce.debounce(() => {
//       this.current_page=1;
//       this.search_keyword=this.searchForm.value.search;
//       console.log('searchKeyword', this.search_keyword)
//       this.get_stores_list();
//     }, 500); 
//     this.searchForm = this.fb.group({
//       search:['']
//     });
//     this.limitKey = this.debounce.debounce(() => {
//       this.current_page=1;
//       this.size=this.limitForm.value.limit;
//       this.get_stores_list();
//     }, 500);  
//     this.limitForm = this.fb.group({
//       limit:['']
//     });
//   }
  
//   disable(){    
//     let details = {
// 			edit_details: {
// 				title: "intererpelorts.megapay.ph says",
// 				content: "Are you sure you want to disable this user?",
// 				btn_cancel: "No",
// 				btn_save: "Yes",
// 			},
// 			purpose: 'disable_user',
// 			comment_data: [],
//       post_id : [],
//       type :  [],
// 		}
// 		this.modal.setModalData(details, 'confirmationModal');
// 		this.modal.open('confirmationModal');
//   }

//   enable(){    
//     let details = {
// 			edit_details: {
// 				title: "intererpelorts.megapay.ph says",
// 				content: "Are you sure you want to enable this user",
// 				btn_cancel: "No",
// 				btn_save: "Yes",
// 			},
// 			purpose: 'enable_user',
// 			comment_data: [],
//       post_id : [],
//       type :  [],
// 		}
// 		this.modal.setModalData(details, 'confirmationModal');
// 		this.modal.open('confirmationModal');
//   }

//   store(){
//     this.spinner.show();
//     let formData = new FormData();
//     formData.append('productName', this.storeItemsForm.controls.productName.value);
//     formData.append('productDesc', this.storeItemsForm.controls.productDesc.value);
//     formData.append('productCode', this.storeItemsForm.controls.productCode.value);
//     formData.append('productPrice', this.storeItemsForm.controls.productPrice.value);
//     formData.append('salesStartDate', moment(this.storeItemsForm.controls.salesStartDate.value).format('YYYY-MM-DD HH:mm:ss'));
//     formData.append('salesEndDate', moment(this.storeItemsForm.controls.salesEndDate.value).format('YYYY-MM-DD HH:mm:ss'));
//     formData.append('category',this.category);
//     formData.append('goodsImgUrl', this.storeItemsForm.controls.goodsImgUrl.value);
    
//     // this.request.post(Urls.store_storeItems, formData).then(response => {
//     //   console.log(response);
//     //   this.page=this.lastPage;
//     //   this.get_stores_list();
//     //   this.toastr.success(response['message']+'!', 'Success!');
//     //   setTimeout(() => {
//     //     this.spinner.hide();
//     //   }, 500);
//     // })
//     this.storeItemsForm.reset();
//     this.category_text=null; 
//   }
//   get_stores_list(){
//     this.spinner.show();
//     let params ={ 
//       title : this.search_keyword,
//     }
    
//   //   this.request.post(Urls.storeItems + '?page=' + this.current_page + '&size=' + this.size, params).then(response => {
//   //     if(response['status']==200){
//   //       this.stores_list=response['result']['data'];
//   //       if(this.current_page % 3 === 0  ){
//   //         this.pages_array=[];
//   //         for(let i=this.current_page; i<=response['result']['totalPages'];i++){
//   //           this.pages_array.push(i);
//   //         };
//   //       }else if(this.current_page==1){
//   //         for(let i=1; i<=response['result']['totalPages'];i++){
//   //           this.pages_array.push(i);
//   //         };
//   //       }else if(this.current_page==response['result']['totalPages']){
//   //         for(let i=this.current_page-2; i<=response['result']['totalPages'];i++){
//   //           this.pages_array.push(i);
//   //         };
//   //       }else{
//   //         for(let i=this.current_page-1; i<=response['result']['totalPages'];i++){
//   //           this.pages_array.push(i);
//   //         };
//   //       }
//   //       this.total_pages=parseInt(response['result']['totalPages']);
//   //       this.current_page=response['result']['currentPage'];
//   //       this.total=parseInt(response['result']['totalItems']);
//   //       setTimeout(() => {
//   //         this.spinner.hide();
//   //       }, 500);
//   //     }
//   //   }).finally(()=>{
//   // });
//   }
//   setCategory(text,value){
//     this.category=value;
//     this.category_text=text;
//   }
//   setPage(num){
//     this.current_page = num;
//     this.get_stores_list();
//   }
//   nextPage(){
//     if(this.current_page < Number(this.total_pages)){
//          this.current_page++;
//          this.get_stores_list()
//      }
//    }
//   prevPage(){
//     if(this.current_page <= Number(this.total_pages)){
//       this.current_page--;
//       this.get_stores_list();
//     }
//   }
//   keyDownFunction(event) {
//     if (event.keyCode === 13) {
//       this.searchList();
//     }
//   }
//   searchList(){
//     this.current_page=1;
//     this.search_keyword=this.searchForm.value.search;
//     console.log('searchKeyword', this.search_keyword)
//     this.get_stores_list();
//   }

//   changeStatus(id, value){
//     let formData = new FormData();
//     formData.append('status', value);
//     // this.request.put(Urls.storeItems + '/' + id, formData).then(response => {
//     //   console.log(response);
//     //   this.get_stores_list();
//     //   this.toastr.success(response['message']+'!', 'Succes!');
//     // })
//   }

//   setheader(index){
//     localStorage.setItem('navigate', index);
//   }
// }

constructor() { }

ngOnInit(): void {
}

}

