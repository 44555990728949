import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  selected_tab: any = 1;

  constructor() { }

  ngOnInit(): void {
  }

  selectTab(tab){
    this.selected_tab = tab
  }

}
