<div class="h-full w-full">
    <div class="px-8 py-5">
        <!-- tabs -->
        <div class="flex items-center space-x-1 text-sm justify-center mb-3">
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 1 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(1)">
                all transaction
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 2 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(2)">
                buy load
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 3 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(3)">
                cash in
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 4 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(4)">
                pay bills
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 5 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(5)">
                transfer funds
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 6 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(6)">
                store purchase
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 7 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(7)">
                mega reward
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 8 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(8)">
                promo
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 9 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(9)">
                overview
            </button>
        </div>
    </div>
    <!-- rendered tab -->
    <div class="w-full h-full">
        <ng-container *ngIf="selected_tab == 1">
            <app-all-transaction></app-all-transaction>
        </ng-container>
        <ng-container *ngIf="selected_tab == 2">
            <app-buy-load></app-buy-load>
        </ng-container>
        <ng-container *ngIf="selected_tab == 3">
            <app-cash-in></app-cash-in>
        </ng-container>
        <ng-container *ngIf="selected_tab == 4">
            <app-pay-bills></app-pay-bills>
        </ng-container>
        <ng-container *ngIf="selected_tab == 5">
            <app-transfer-funds></app-transfer-funds>
        </ng-container>
        <ng-container *ngIf="selected_tab == 6">
            <app-store-purchase></app-store-purchase>
        </ng-container>
        <ng-container *ngIf="selected_tab == 7">
            <app-mega-reward></app-mega-reward>
        </ng-container>
        <ng-container *ngIf="selected_tab == 8">
            <app-promo></app-promo>
        </ng-container>
        <ng-container *ngIf="selected_tab == 9">
            <app-overview></app-overview>
        </ng-container>
        
    </div>

</div>