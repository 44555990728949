import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-all-transaction',
  templateUrl: './all-transaction.component.html',
  styleUrls: ['./all-transaction.component.scss']
})
export class AllTransactionComponent implements OnInit {

  transaction:any =[
    {
      id: '00001',
      date: 1625984309000,
      name: 'michael reyes',
      phone_number: '09206428863',
      type: 'buy load cash back',
      status: 'success'
    },
    {
      id: '00003',
      date: 1625984309000,
      name: 'michael reyes',
      phone_number: '09206428863',
      type: 'buy load cash back',
      status: 'success'
    },
    {
      id: '00004',
      date: 1625984309000,
      name: 'michael reyes',
      phone_number: '09206428863',
      type: 'buy load cash back',
      status: 'success'
    },
  ]

  constructor() { }

  ngOnInit(): void {
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }
  }

}
