import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators,FormControl} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { DebounceService } from 'src/app/services/debounce.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sku-pay-bills',
  templateUrl: './sku-pay-bills.component.html',
  styleUrls: ['./sku-pay-bills.component.scss']
})
export class SkuPayBillsComponent implements OnInit {
  public payBillsForm: FormGroup;
  paybills_lists:any;
  isLoading: true;
  lastPage:any; 
  status:number=1;
 
  //add biller
  isDisplay= true;
  toggleDisplay(){
    this.isDisplay= !this.isDisplay;
  }

  // Biller Category
  billerCategory_text:any;
  billerCategory:any;

  // First Field Format
  firstFieldFormat_text:any;
  firstFieldFormat:any;

  //Second Field Format
  secondFieldFormat_text:any;
  secondFieldFormat:any;

//pagination
  page=1;
  size=10;
  total_pages:any;
  current_page = 1;
  pages_array=[
  ];
  total:any;

  //search
  searchKey: any=null;
  searchForm : FormGroup;
  search_keyword='';

  //limit
  limitKey:any=null;
  limitForm : FormGroup;
 
  constructor(
    private modal: NgxSmartModalService,
    private fb : FormBuilder,
    public debounce: DebounceService,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.initializeForm()
   }

  ngOnInit(): void {
    this.get_payBills_list();
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }
   
  }
  initializeForm(){
    this.payBillsForm = this.fb.group({
      billerName: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      billerDescription: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      firstFieldName: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      firstFieldWidth: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      
      secondFieldName: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      secondFieldWidth: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      convenienceFee:[
        '',
        Validators.compose([
          Validators.required
        ])
      ]
    });

    this.searchKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.search_keyword=this.searchForm.value.search;
      console.log('searchKeyword', this.search_keyword)
      this.get_payBills_list();
    }, 500); 

    this.searchForm = this.fb.group({
      search:['']
    });

    this.limitKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.size=this.limitForm.value.limit;
      this.get_payBills_list();
    }, 500);

    this.limitForm = this.fb.group({
      limit:['']
    });
  }
  store(){
    this.spinner.show();
    this.request.post(Urls.store_billers, {
      billerName:this.payBillsForm.controls.billerName.value,
      billerDescription:this.payBillsForm.controls.billerDescription.value,
      firstFieldName:this.payBillsForm.controls.firstFieldName.value,
      firstFieldWidth:this.payBillsForm.controls.firstFieldWidth.value,
      secondFieldName:this.payBillsForm.controls.secondFieldName.value,
      secondFieldWidth:this.payBillsForm.controls.secondFieldWidth.value,
      convenienceFee:this.payBillsForm.controls.convenienceFee.value,
      billerCategory:this.billerCategory,
      firstFieldFormat:this.firstFieldFormat,
      secondFieldFormat:this.secondFieldFormat,
    }).then(response => {
      console.log(response);
      this.page=this.lastPage;
          this.get_payBills_list();
          this.toastr.success(response['message']+'!');
          setTimeout(() => {
            this.spinner.hide();
          }, 500);
        })
        this.payBillsForm.reset();
        this.billerCategory_text=null;
        this.firstFieldFormat_text=null;
        this.secondFieldFormat_text=null;
  } 

  get_payBills_list(){
    this.spinner.show();
    let params ={ 
      title : this.search_keyword,
    }
    this.request.post(Urls.billers + '?page=' + this.current_page + '&size=' + this.size, params).then(response => {
      if(response['status']==200){
        this.paybills_lists=response['result']['data'];
        if(this.current_page % 3 === 0  ){
          this.pages_array=[];
          for(let i=this.current_page; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else if(this.current_page==1){
          for(let i=1; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else if(this.current_page==response['result']['totalPages']){
          for(let i=this.current_page-2; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else{
          for(let i=this.current_page-1; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }
        this.total_pages=parseInt(response['result']['totalPages']);
        this.current_page=response['result']['currentPage'];
        this.total=parseInt(response['result']['totalItems']);
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
    }).finally(()=>{
  });   

  }
  setBillerCategory(text,value){
      this.billerCategory=value;
      this.billerCategory_text=text;
  }
  setFirstFieldFormat(text,value){
    this.firstFieldFormat=value;
    this.firstFieldFormat_text=text;
  }
  setSecondFieldFormat(text,value){
    this.secondFieldFormat=value;
    this.secondFieldFormat_text=text;
  }
  
  setPage(num){
    this.current_page = num;
    this.paybills_lists();
  }
  nextPage(){
    if(this.current_page < Number(this.total_pages)){
         this.current_page++;
         this.paybills_lists()
     }
   }
  prevPage(){
    if(this.current_page <= Number(this.total_pages)){
      this.current_page--;
      this.paybills_lists();
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.searchList();
    }
  }
  searchList(){
    this.current_page=1;
    this.search_keyword=this.searchForm.value.search;
    console.log('searchKeyword', this.search_keyword)
    this.paybills_lists();
  }
  changeStatus(id){
    if(this.status == 1 ){
      this.status = 0;
    }else{
      this.status = 1;
    }
    this.request.put(Urls.billers + '/' + id,{
      status:this.status
      }).then(response => {
      console.log(response);
      this.get_payBills_list()
      this.toastr.success(response['message']);
    })
  }
  setheader(index){
    localStorage.setItem('navigate', index);
  } 
}
 
