<div class="h-screen w-screen login-bg overflow-hidden">
    <div class="container mx-auto">
        <!-------------------------- header ------------------------>
        <div class="h-120-px w-full flex items-center justify-between" >
            <img src="assets/images/logos/main_logo.png" class="w-250-px h-100-px object-contain" alt="">
            <div class="flex items-center space-x-4 text-white">
                <div class="cursor-pointer">
                    About
                </div>
                <div class="cursor-pointer">
                    Contact us
                </div>
            </div>
        </div>
        <!-- login -->
        <div class="h-full w-800-px mx-auto">
            <div class="items-center rounded-3xl flex justify-between">
                <img src="assets/images/login_bg.png" class="w-350-px h-full object-contain" alt="">
                <div class="w-330-px rounded-3xl bg-white minh-300-px p-5 fade-in" *ngIf="!show_forgot" >
                    <form [formGroup]="loginForm">
                        <div class="text-center orange-text font-semibold text-4xl mb-5">
                            Portal
                        </div>
                        <!-- input -->
                        <div class="flex flex-col space-y-5">
                            <div class="flex items-center bg-lavender rounded-3xl h-35-px w-full px-3">
                                <span class="mr-3">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14.264 13.141">
                                        <path id="Path_354" data-name="Path 354" d="M1551.814,891.959a3.264,3.264,0,0,1,.124-.5,4.35,4.35,0,0,1,1.24-1.836,6.508,6.508,0,0,1,1.827-1.163,6.741,6.741,0,0,1,1.31-.41,6.263,6.263,0,0,1,1.1-.126,2.159,2.159,0,0,1,.3,0c.143.009.287.019.43.031a5.532,5.532,0,0,1,.851.146,5.934,5.934,0,0,1,1.5.594,5.378,5.378,0,0,1,1.342,1.053,4.512,4.512,0,0,1,.8,1.207,3.784,3.784,0,0,1,.293,1h1.562c0-.111-.022-.219-.03-.329a3.689,3.689,0,0,0-.14-.715,4.906,4.906,0,0,0-.52-1.18,6.165,6.165,0,0,0-.823-1.068c-.09-.1-.19-.181-.286-.271a5.381,5.381,0,0,0-.435-.37,7.7,7.7,0,0,0-.824-.55,7.513,7.513,0,0,0-1.124-.538c-.117-.044-.233-.09-.347-.134a4.388,4.388,0,0,0,1.882-3.422.415.415,0,0,1,.01-.152.1.1,0,0,1-.013-.071,3.007,3.007,0,0,0-.045-.624,4.2,4.2,0,0,0-.2-.8,4.374,4.374,0,0,0-.881-1.477,4.157,4.157,0,0,0-.7-.628,4.517,4.517,0,0,0-1.285-.629,4.6,4.6,0,0,0-1.2-.184c-.094,0-.188.011-.281.011a2.654,2.654,0,0,0-.411.037,4.565,4.565,0,0,0-.974.26,4.261,4.261,0,0,0-1.323.824,4.357,4.357,0,0,0-1.094,1.545,4.46,4.46,0,0,0-.339,1.448,3.79,3.79,0,0,0,.009.588,4.75,4.75,0,0,0,.133.79,4.385,4.385,0,0,0,1.114,1.955,4.577,4.577,0,0,0,.539.464l-.068.022a8.283,8.283,0,0,0-1.5.66,7.042,7.042,0,0,0-1.534,1.165,5.911,5.911,0,0,0-.9,1.16,4.722,4.722,0,0,0-.649,1.915c-.01.1-.018.2-.023.3Zm2.924-7.469a3.369,3.369,0,0,1-.2-.71c-.017-.1-.017-.2-.032-.3a.1.1,0,0,1,.012-.041,1.563,1.563,0,0,1,0-.3,2.822,2.822,0,0,1,.334-1.215,2.913,2.913,0,0,1,.663-.846,2.98,2.98,0,0,1,1.6-.722,3.061,3.061,0,0,1,.5-.026,3.014,3.014,0,0,1,1.648.57,2.946,2.946,0,0,1,.718.738,2.852,2.852,0,0,1,.437,1,2.628,2.628,0,0,1,.076.514.829.829,0,0,1,0,.33,2.993,2.993,0,0,1-1.018,2.1,2.965,2.965,0,0,1-2.217.739,2.893,2.893,0,0,1-.964-.248,3.033,3.033,0,0,1-1.012-.734A2.909,2.909,0,0,1,1554.738,884.49Z" transform="translate(-1550.233 -878.818)" fill="#5f4d9c"/>
                                    </svg>
                                </span>
                                <input type="text" class="focus:outline-none no-bg" placeholder="Username" formControlName="username">
                            </div>
                            <div class="flex items-center bg-lavender rounded-3xl h-35-px w-full px-3">
                                <span class="mr-3">
                                    <svg id="Group_56" data-name="Group 56" xmlns="http://www.w3.org/2000/svg" width="13.608" height="16.817" viewBox="0 0 13.608 16.817">
                                        <path id="Path_270" data-name="Path 270" d="M1567.495,1050.6a1.42,1.42,0,0,0-.983-1.352,5.2,5.2,0,0,0-.713-.18c0-.057,0-.132,0-.208-.021-.864.028-1.734-.077-2.589a4.581,4.581,0,0,0-3.694-3.588c-.313-.068-.634-.107-.952-.16h-.764a.818.818,0,0,1-.11.023,5.377,5.377,0,0,0-3.042,1.206,3.952,3.952,0,0,0-1.544,2.876c-.041.754-.02,1.51-.027,2.265,0,.064,0,.129,0,.2-.143.019-.271.027-.392.055a1.5,1.5,0,0,0-1.3,1.468q-.005,3.6,0,7.207a1.589,1.589,0,0,0,1.759,1.512q5.041.005,10.083-.005a2.188,2.188,0,0,0,.79-.149,1.41,1.41,0,0,0,.97-1.391v-5.877C1567.5,1051.479,1567.5,1051.042,1567.495,1050.6Zm-10.2-3.172c0-.273.015-.546.042-.817.006-.054.012-.108.018-.163a2.13,2.13,0,0,1,.117-.482c.02-.055.041-.109.064-.162a2.514,2.514,0,0,1,.2-.355,2.7,2.7,0,0,1,.162-.22c.029-.036.059-.071.09-.105a3.34,3.34,0,0,1,1.033-.763c.089-.043.18-.082.273-.119s.188-.068.285-.1.2-.054.295-.075.2-.038.3-.052c.051-.006.1-.012.154-.016s.106-.008.16-.01q.153-.007.308,0a3.771,3.771,0,0,1,2.244.8,2.981,2.981,0,0,1,.507.526,2.742,2.742,0,0,1,.3.492,2.761,2.761,0,0,1,.107.268c.018.054.034.108.049.163.021.079.039.159.054.24,0,.012,0,.025.006.037a2.731,2.731,0,0,1,.036.432q0,.5,0,.994t0,.993v.155h-6.775c-.009-.009-.017-.013-.016-.018,0-.109,0-.218,0-.328v0c0-.383-.013-.766-.011-1.149C1557.293,1047.542,1557.293,1047.487,1557.294,1047.432Zm8.489,10.446h-.214q-4.871,0-9.741,0c-.193,0-.243-.041-.243-.208.007-2.3,0-4.608,0-6.913v-.183h10.194Z" transform="translate(-1553.891 -1042.526)" fill="#5f4d9c"/>
                                        <path id="Path_271" data-name="Path 271" d="M1567.989,1070.482c0-.017,0-.033,0-.049a.692.692,0,0,0-.332-.726,1.239,1.239,0,0,1-.37-1.622,1.742,1.742,0,0,1,1.7-.848,1.619,1.619,0,0,1,1.5,1.109,1.338,1.338,0,0,1-.678,1.525.215.215,0,0,0-.126.214c.007.257.006.514,0,.771a.852.852,0,0,1-1.692,0C1567.986,1070.734,1567.989,1070.608,1567.989,1070.482Z" transform="translate(-1562.032 -1057.718)" fill="#5f4d9c"/>
                                    </svg>
                                </span>
                                <input type="password" class="focus:outline-none no-bg" placeholder="password" formControlName="password">
                            </div>
                        </div>
                        <!-- end of input -->
                        <div class="flex items-center mt-5">
                            <!-- <mat-checkbox class="custom-frame">Circular checkbox</mat-checkbox> -->
                            <input type="checkbox" class="checkbox-round z-10" #rememberMe>
                            <span class="material-icons violet-text left-0 z-20 text-xs font-semibold check-icon cursor-pointer" (click)="rememberMe.click()">
                                done
                            </span>
                            <div class="violet-text text-base">
                                Remember me
                            </div>
                        </div>
                        <button class="custom-btn btn-orange-round w-220-px btn-orange-round rounded-3xl py-2 mt-3  text-lg font-bold mx-auto flex justify-center focus:outline-none" (click)="login()">
                            Login
                        </button>
                        <div class="text-center text-base violet-text cursor-pointer mt-3 hover:text-indigo-700" (click)="forgotPass()">
                            Forget password?
                        </div>
                    </form>
                </div>

            <!-- forgot -->
            <ng-container *ngIf="show_forgot">
                <div class="w-330-px rounded-3xl bg-white minh-300-px p-5 fade-in">
                    <div class="text-center orange-text font-semibold text-3xl mb-5">
                        Forgot Password
                    </div>
                    <!-- input -->
                    <div class="flex flex-col space-y-5">
                        <div class="flex items-center bg-lavender rounded-3xl h-35-px w-full px-3">
                            <span class="mr-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14.264 13.141">
                                    <path id="Path_354" data-name="Path 354" d="M1551.814,891.959a3.264,3.264,0,0,1,.124-.5,4.35,4.35,0,0,1,1.24-1.836,6.508,6.508,0,0,1,1.827-1.163,6.741,6.741,0,0,1,1.31-.41,6.263,6.263,0,0,1,1.1-.126,2.159,2.159,0,0,1,.3,0c.143.009.287.019.43.031a5.532,5.532,0,0,1,.851.146,5.934,5.934,0,0,1,1.5.594,5.378,5.378,0,0,1,1.342,1.053,4.512,4.512,0,0,1,.8,1.207,3.784,3.784,0,0,1,.293,1h1.562c0-.111-.022-.219-.03-.329a3.689,3.689,0,0,0-.14-.715,4.906,4.906,0,0,0-.52-1.18,6.165,6.165,0,0,0-.823-1.068c-.09-.1-.19-.181-.286-.271a5.381,5.381,0,0,0-.435-.37,7.7,7.7,0,0,0-.824-.55,7.513,7.513,0,0,0-1.124-.538c-.117-.044-.233-.09-.347-.134a4.388,4.388,0,0,0,1.882-3.422.415.415,0,0,1,.01-.152.1.1,0,0,1-.013-.071,3.007,3.007,0,0,0-.045-.624,4.2,4.2,0,0,0-.2-.8,4.374,4.374,0,0,0-.881-1.477,4.157,4.157,0,0,0-.7-.628,4.517,4.517,0,0,0-1.285-.629,4.6,4.6,0,0,0-1.2-.184c-.094,0-.188.011-.281.011a2.654,2.654,0,0,0-.411.037,4.565,4.565,0,0,0-.974.26,4.261,4.261,0,0,0-1.323.824,4.357,4.357,0,0,0-1.094,1.545,4.46,4.46,0,0,0-.339,1.448,3.79,3.79,0,0,0,.009.588,4.75,4.75,0,0,0,.133.79,4.385,4.385,0,0,0,1.114,1.955,4.577,4.577,0,0,0,.539.464l-.068.022a8.283,8.283,0,0,0-1.5.66,7.042,7.042,0,0,0-1.534,1.165,5.911,5.911,0,0,0-.9,1.16,4.722,4.722,0,0,0-.649,1.915c-.01.1-.018.2-.023.3Zm2.924-7.469a3.369,3.369,0,0,1-.2-.71c-.017-.1-.017-.2-.032-.3a.1.1,0,0,1,.012-.041,1.563,1.563,0,0,1,0-.3,2.822,2.822,0,0,1,.334-1.215,2.913,2.913,0,0,1,.663-.846,2.98,2.98,0,0,1,1.6-.722,3.061,3.061,0,0,1,.5-.026,3.014,3.014,0,0,1,1.648.57,2.946,2.946,0,0,1,.718.738,2.852,2.852,0,0,1,.437,1,2.628,2.628,0,0,1,.076.514.829.829,0,0,1,0,.33,2.993,2.993,0,0,1-1.018,2.1,2.965,2.965,0,0,1-2.217.739,2.893,2.893,0,0,1-.964-.248,3.033,3.033,0,0,1-1.012-.734A2.909,2.909,0,0,1,1554.738,884.49Z" transform="translate(-1550.233 -878.818)" fill="#5f4d9c"/>
                                </svg>
                            </span>
                            <input type="text" class="focus:outline-none no-bg" placeholder="Username">
                        </div>
                        <div class="flex items-center bg-lavender rounded-3xl h-35-px w-full px-3">
                            <span class="mr-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16.5" height="14.172" viewBox="0 0 15.5 10.172">
                                    <path id="mail" d="M13.563,5H1.938A1.938,1.938,0,0,0,0,6.938v6.3a1.938,1.938,0,0,0,1.938,1.938H13.563A1.938,1.938,0,0,0,15.5,13.234v-6.3A1.938,1.938,0,0,0,13.563,5ZM.969,7.543l3.39,2.543L.969,12.629Zm13.563,5.691a.97.97,0,0,1-.969.969H1.938a.969.969,0,0,1-.969-.969l3.794-2.846,2.116,1.587a1.452,1.452,0,0,0,1.743,0l2.116-1.587,3.794,2.846Zm0-.605-3.391-2.543,3.391-2.543Zm-6.2-1.04a.968.968,0,0,1-1.162,0l-2-1.5-.4-.3L.969,6.938h0a.97.97,0,0,1,.969-.969H13.563a.97.97,0,0,1,.969.969Z" transform="translate(0 -5)" fill="#5f4d9c"/>
                                </svg>  
                            </span>
                            <input type="text" class="focus:outline-none no-bg" placeholder="Email">
                        </div>
                    </div>
                    <!-- end of input -->
                    <div class="flex items-center mt-5">
                        <!-- <mat-checkbox class="custom-frame">Circular checkbox</mat-checkbox> -->
                        <input type="checkbox" class="checkbox-round z-10" #rememberMe>
                        <span class="material-icons violet-text left-0 z-20 text-xs font-semibold check-icon cursor-pointer" (click)="rememberMe.click()">
                            done
                        </span>
                        <div class="violet-text text-base">
                            Remember me
                        </div>
                    </div>
                    <button class="custom-btn w-220-px btn-orange-round rounded-3xl py-2 mt-3  text-lg font-bold mx-auto flex justify-center focus:outline-none">
                        Submit
                    </button>
                    <div class="text-center text-base violet-text cursor-pointer mt-3 hover:text-indigo-700" (click)="forgotPass()">
                        Back to Login
                    </div>
                </div>
            </ng-container>

            </div>
        </div>

    </div>


</div>