<div class="w-full h-full">
    <div class="px-8 mt-8 mb-32">
        <button class="custom-btn btn-light-red px-4 py-1 text-sm" (click)="toggleDisplay()">
            <span class="material-icons text-white mr-1">
                add_circle
            </span>
            Add Biller
        </button>
        <form [hidden]="isDisplay" [formGroup]="payBillsForm">
        <div class="grid grid-cols-12 gap-3 pl-4 mt-6">
            <div class="col-span-6">
                <div class="card-input flex items-center text-sm py-2 w-full">
                    <span class="text-sm w-110-px">
                        Biller Name:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" formControlName="billerName" type="text" placeholder="Biller Name">
                </div>
            </div>
            <div class="col-span-6">
                <div class="card-input flex items-center text-sm py-2 w-full">
                    <div class="flex items-center space-x-3">
                    <span class="text-sm w-120-px">
                        Biller Category:
                    </span>
                        <div #t1="matMenuTrigger" #select1 [matMenuTriggerFor]="billerCategory_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!billerCategory_text" class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                            <span *ngIf="billerCategory_text" class="barlow text-black text-xs font-lg p-5 italic">{{billerCategory_text}}</span>
                            <span class="material-icons violet-text tt-160">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #billerCategory_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic"  >
                                == Select an option ==
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setBillerCategory('Telecom','2')">
                                Telecom
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setBillerCategory('Credit Card','3')">
                                Credit Card
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setBillerCategory('Cable and Internet','4')">
                                Cable and Internet
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setBillerCategory('Government','5')">
                                Government
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setBillerCategory('Loans','6')">
                                Loans
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setBillerCategory('Others','7')">
                                Others
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setBillerCategory('Travel','8')">
                                Travel
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setBillerCategory('Transpo','9')">
                                Transpo
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setBillerCategory('Shopping','10')">
                                Shopping
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setBillerCategory('Power','11')">
                                Power
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setBillerCategory('Water','12')">
                                Water
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="col-span-12">
                <div class="card-input flex items-center text-sm py-2 w-full">
                    <div class="text-sm w-140-px">
                        Biller Description:
                    </div>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" formControlName="billerDescription" type="text" placeholder="Enter Description">
                </div>
            </div>
            <div class="col-span-4">
                <div class="card-input flex items-center text-sm py-2 w-full">
                    <span class="text-sm w-190-px">
                        First Field Name:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" formControlName="firstFieldName" type="text" placeholder="Field Name">
                </div>
                <div class="card-input flex items-center text-sm py-2 w-full mt-3">
                    <span class="text-sm w-240-px">
                        Second Field Name:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" formControlName="secondFieldName" type="text" placeholder="Field Name">
                </div>
            </div>
            <div class="col-span-4">
                <div class="card-input flex items-center text-sm py-2 w-full mb-3 ">
                    <div class="flex items-center space-x-3">
                    <span class="text-sm w-119-px">
                        First Field Format:
                    </span>
                        <div #2="matMenuTrigger" #select2 [matMenuTriggerFor]="firstField_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!firstFieldFormat_text"  class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                            <span *ngIf="firstFieldFormat_text" class="barlow text-black text-xs font-lg p-5 italic">{{firstFieldFormat_text}}</span>
                            <span class="material-icons violet-text mr-1">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #firstField_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" >
                                == Select an option ==
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setFirstFieldFormat('Numeric','1')">
                                Numeric
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setFirstFieldFormat('Alphanumeric','2')">
                               Alphanumeric
                            </button>
                        </mat-menu>
                    </div>
                </div>
                <div class="card-input flex items-center text-sm py-2 w-full mt-3">
                    <div class="flex items-center space-x-3">
                    <span class="text-sm w-137-px">
                        Second Field Format:
                    </span>
                        <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="secondField_select" class=" rounded-md py-3 pl-0  minw-200-px flex justify-between items-center h-30-px cursor-pointer">
                            <span *ngIf="!secondFieldFormat_text"  class="barlow text-black text-xs font-lg p-5 italic">== Select an option ==</span>
                            <span *ngIf="secondFieldFormat_text" class="barlow text-black text-xs font-lg p-5 italic">{{secondFieldFormat_text}}</span>
                            <span class="material-icons violet-text ml-7 -tt-23">
                                arrow_drop_down
                            </span>
                        </div>
                        <mat-menu class="w-full sports-menu" #secondField_select="matMenu">
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" >
                                == Select an option ==
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setSecondFieldFormat('Numeric','1')">
                              Numeric
                            </button>
                            <button mat-menu-item class="tracking-wide focus:outline-none  italic" (click)="setSecondFieldFormat('Alphanumeric','2')">
                               Alphanumeric
                            </button>
                        </mat-menu>
                    </div>
                </div>
            </div>
            <div class="col-span-4">
                <div class="card-input flex items-center text-sm py-2 w-full">
                    <span class="text-sm w-300-px">
                        First Field Max Length:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" formControlName="firstFieldWidth" type="number" placeholder="Length">
                </div>
                <div class="card-input flex items-center text-sm py-2 w-full mt-3">
                    <span class="text-sm w-410-px">
                        Second Field Max Length:
                    </span>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" formControlName="secondFieldWidth"  type="number" placeholder="Length">
                </div>
            </div>
            <div class="col-span-12">
                <div class="card-input flex items-center text-sm py-2 w-full">
                    <div class="text-sm w-140-px">
                        Convenience Fee:
                    </div>
                    <input class="placeholder-black focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3" formControlName="convenienceFee" type="number" placeholder="Enter Description">
                </div>
          
            </div>

            <div class="col-span-12">
                <div class="w-full h-full flex items-end justify-center mb-5">
                    <button  (click)="store()" class="uppercase custom-btn btn-dark-orange font-semibold text-sm px-6 py-2">
                        submit
                    </button>
                </div>
            </div>
        </div>
    </form>
    </div>


<div class="bg-light-orange w-full maxh-400-px py-4 px-3 overflow-x-auto w-table">
    <div class="w-full flex items-center justify-between space-x-3 pr-3 text-sm">
        <div class="flex items-center" [formGroup]="limitForm">
            <div class="orange-text">show:</div>
            <input class="orange-input focus:outline-none px-2 py-1 mx-2 w-35-px" type="number" formControlName="limit" (change)="limitKey($event)" value="{{size}}">
            <div class="orange-text">entries</div>

        </div>
        <div class="flex items-center space-x-3" [formGroup]="searchForm" (keydown)="keyDownFunction($event)">
            <div class="orange-text">Search:</div>
            <input class="orange-input focus:outline-none px-2 py-1" type="text" placeholder="Search" formControlName="search" (keyup)="searchKey($event)">
        </div>
    </div>
        <div class="data-table mt-3 barlow text-xs">
            <table class="w-full">
                <thead class="capitalize">
                    <tr>
                        <th class="text-left barlow">
                            NAME
                        </th>
                        <th class="text-left barlow">
                            Category
                        </th>
                        <th class="text-left barlow">
                            Description
                        </th>
                        <th class="text-left barlow">
                            label
                        </th>
                        <th class="text-left barlow">
                            format
                        </th>
                        <th class="text-left barlow">
                            max length
                        </th>
                        <th class="text-left barlow">
                            label
                        </th>
                        <th class="text-left barlow">
                            format
                        </th>
                        <th class="text-left barlow">
                            max length
                        </th>
                        <th class="text-left barlow">
                            conv fee
                        </th>
                        <th class="text-left barlow">
                            status
                        </th>
                        <th class="text-left barlow">
                            limit
                        </th>
                        <th class="text-left barlow">
                            image url
                        </th>
                        <th class="text-left barlow">
                            action
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container >
                        <tr  class="focus:outline-none" *ngFor="let paybills of paybills_lists "  >
                            <td >{{paybills.billerName}}</td>
                            <td *ngIf="paybills.billerCategory">
                                <p *ngIf="paybills.billerCategory==2">Telecom</p>
                                <p *ngIf="paybills.billerCategory==3">Credit Card</p>
                                <p *ngIf="paybills.billerCategory==4">Cable and Internet</p>
                                <p *ngIf="paybills.billerCategory==5">Government</p>
                                <p *ngIf="paybills.billerCategory==6">Loans</p>
                                <p *ngIf="paybills.billerCategory==7">Others</p>
                                <p *ngIf="paybills.billerCategory==8">Travel</p>
                                <p *ngIf="paybills.billerCategory==9">Transpo</p>
                                <p *ngIf="paybills.billerCategory==10">Shopping</p> 
                                <p *ngIf="paybills.billerCategory==11">Power</p>
                                <p *ngIf="paybills.billerCategory==12">Water</p>
                            </td>
                            <td >{{paybills.billerDescription}}</td>
                            <td >{{paybills.firstFieldName}}</td>
                            <td *ngIf="paybills.firstFieldFormat">
                                <p *ngIf="paybills.firstFieldFormat==1">Numeric</p>
                                <p *ngIf="paybills.firstFieldFormat==2">Alphanumeric</p>
                            </td>
                            <td >{{paybills.firstFieldWidth}}</td>
                            <td >{{paybills.secondFieldName}}</td>
                            <td *ngIf="paybills.secondFieldFormat">
                                <p *ngIf="paybills.secondFieldFormat==1">Numeric</p>
                                <p *ngIf="paybills.secondFieldFormat==2">Alphanumeric</p>
                            </td>
                            <td >{{paybills.secondFieldWidth}}</td>
                            <td >{{paybills.convenienceFee}}</td>
                            <td>
                                <p *ngIf="paybills.status==0">Active</p>
                                <p *ngIf="paybills.status!==0">Inactive</p>
                            </td>
                            <td >{{paybills.imageUrl}}</td>
                            <td class="text-xs" >
                                <ng-container *ngIf="buyLoad" >
                                    <button class="power-btn" *ngIf="buyLoad.status==0" (click) = "changeStatus(buyLoad.uid,1)">
                                        <span class="material-icons text-sm" >
                                            power_settings_new
                                        </span>
                                    </button>
                                    <button class="power-btn active"  *ngIf="buyLoad.status==1" (click) = "changeStatus(buyLoad.uid,0)">
                                        <span class="material-icons text-sm" >
                                            power_settings_new
                                        </span>
                                    </button>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-container>
                    <!-- <ng-container *ngIf="isLoading">
                        <ng-container *ngFor="let item of [1,2,3,4]">
                            <tr>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                                <td>
                                    <div class="w-full h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]="">
                                        </app-loader>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                    </ng-container> -->
                </tbody>
            </table>
        </div>
        <div class="col-span-full flex justify-between items-center">
            <div class="orange-text text-sm">
                showing {{size}} of {{total}} entree
            </div>
            <div class="pr-3 flex items-center justify-end table-pagination mt-6" style="text-align: right;">
            <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;" [disabled] ="this.current_page == 1" (click)="prevPage()">
                Previous
            </button>
            <ng-container *ngFor="let item of pages_array | slice:0:3; let i=index;">
                <button class="page-btn sarabun-bold orange-box px-2" [ngClass]="{'active':current_page == item}" style="white-space: nowrap;" (click)="setpage(total_pages)">
                    {{item}}
                </button>
            </ng-container>
            <ng-container *ngIf="total_pages>3&&current_page+1<total_pages">
                <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;">
                    ...
                </button> 
            </ng-container>
            <ng-container *ngIf="total_pages>3&&current_page+1<total_pages">
                <button class="page-btn sarabun-bold orange-box px-2" [ngClass]="{'active': current_page == item}" style="white-space: nowrap;" (click) = "setPage(total_pages)">
                    {{total_pages}}
                </button> 
            </ng-container>
            <button class="page-btn sarabun-bold orange-box px-2" style="white-space: nowrap;" [disabled]="this.current_page == total_pages " (click)= "nextPage()">
                Next 
            </button> 
          </div>
        </div>
    </div>
</div>