import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss']
})
export class AccountsComponent implements OnInit {
  transaction:any =[
    {
      id: '00001',
      date: 1625984309000,
      name: 'michael reyes',
      phone_number: '09206428863',
      recipient_number: '09206428863',
      product_id: 'UTP15',
      item_type: 'load',
      load_provider: 'TNT',
      amount: '15.00',
      trace_number: 'SDFGD826G',
      type: 'load',
      status: 'success',
      date_modified: 1625984309000,
      payment_method: 'credit card',
      charge: '00.00',
      card_number: 1236547896325,
      acc_number: '0000000000000',
      acc_name: 'John jojo',
      biller_name: 'Metrobank',
      category: 'credit card',
      amount_due: '40.00',
      conv_fee: '0.00',
      sender_name: 'kelly kit',
      sender_number: '09206428863',
      recipient_name: 'gerard kit',
      transaction_type: 'tranfer load',
      product_name: '1pc chicken',
      product_code: 'C136',
      recipient_email: 'john@email.com',
      f_name: 'ailo',
      m_name: 'grand',
      l_name: 'prix',
      acc_type: 'merchant',
      tier_type: 'fully verified'
    },
    {
      
      id: '00001',
      date: 1625984309000,
      name: 'michael reyes',
      phone_number: '09206428863',
      recipient_number: '09206428863',
      product_id: 'UTP15',
      item_type: 'load',
      load_provider: 'TNT',
      amount: '15.00',
      trace_number: 'SDFGD826G',
      type: 'load',
      status: 'success',
      date_modified: 1625984309000,
      payment_method: 'credit card',
      charge: '00.00',
      card_number: 1236547896325,
      acc_number: '0000000000000',
      acc_name: 'John jojo',
      biller_name: 'Metrobank',
      category: 'credit card',
      amount_due: '40.00',
      conv_fee: '0.00',
      sender_name: 'kelly kit',
      sender_number: '09206428863',
      recipient_name: 'gerard kit',
      transaction_type: 'tranfer load',
      product_name: '1pc chicken',
      product_code: 'C136',
      recipient_email: 'john@email.com',
      f_name: 'ailo',
      m_name: 'grand',
      l_name: 'prix',
      acc_type: 'merchant',
      tier_type: 'fully verified'
    },
    {
      
      id: '00001',
      date: 1625984309000,
      name: 'michael reyes',
      phone_number: '09206428863',
      recipient_number: '09206428863',
      product_id: 'UTP15',
      item_type: 'load',
      load_provider: 'TNT',
      amount: '15.00',
      trace_number: 'SDFGD826G',
      type: 'load',
      status: 'success',
      date_modified: 1625984309000,
      payment_method: 'credit card',
      charge: '00.00',
      card_number: 1236547896325,
      acc_number: '0000000000000',
      acc_name: 'John jojo',
      biller_name: 'Metrobank',
      category: 'credit card',
      amount_due: '40.00',
      conv_fee: '0.00',
      sender_name: 'kelly kit',
      sender_number: '09206428863',
      recipient_name: 'gerard kit',
      transaction_type: 'tranfer load',
      product_name: '1pc chicken',
      product_code: 'C136',
      recipient_email: 'john@email.com',
      f_name: 'ailo',
      m_name: 'grand',
      l_name: 'prix',
      acc_type: 'merchant',
      tier_type: 'fully verified'
    },
  ]
  constructor(
    private modal: NgxSmartModalService
  ) { }

  ngOnInit(): void {
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }
  }

  openModal(type){
    let content_main = type == 'green' ? "Are you sure you want to enable the transaction?" : "Are you sure you want to disable the transaction?";
    let details = {
			edit_details: {
				title: "intererpelorts.megapay.ph says",
				content: content_main,
				btn_cancel: "No",
				btn_save: "Yes",
			},
			purpose: type == 'green' ? 'enable_account' :'disable_account',
			comment_data: [],
      post_id : [],
      type :  [],
		}
		this.modal.setModalData(details, 'confirmationModal');
		this.modal.open('confirmationModal');

  }

}
