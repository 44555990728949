<div class="w-1000-px mx-auto">
    <div class="flex items-center space-x-3 text-sm justify-start mb-3 mt-4">
        <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 1 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(1)">
            information
        </button>
        <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 2 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(2)">
            kyc details
        </button>
        <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 3 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(3)">
            edit account
        </button>
    </div>
    <hr class="border border-gray-300 w-full mt-1 mb-3">
    <div class="w-full h-full">
        <ng-container *ngIf="selected_tab == 1">
            <app-accounts-information></app-accounts-information>
        </ng-container>
        <ng-container *ngIf="selected_tab == 2">
            <app-accounts-kyc-detail></app-accounts-kyc-detail>
        </ng-container>
        <ng-container *ngIf="selected_tab == 3">
            <app-accounts-edit></app-accounts-edit>
        </ng-container>
    </div>
</div>