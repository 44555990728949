<div class="h-70-px shadow-md w-full bg-white">
    <div class="flex items-center justify-between h-full px-10">
        <span class="font-semibold uppercase" *ngIf="!title_is_array">{{ navigation_title }}</span>
        <div class="grid grid-cols-4 gap-1" *ngIf="title_is_array">
            <div class="col-span-1" *ngFor="let item of navigation_title">
                <span class="title uppercase cursor-pointer" [routerLink]="item.route" routerLinkActive="active-route" >
                    {{ item.title }}
                </span>
            </div>
        </div>
        <div class="flex items-center">
            <span class="material-icons mr-3 cursor-pointer" *ngIf="title == 'dashboard'">
                refresh
            </span>
            <svg class="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="25" height="21.433" viewBox="0 0 25 21.433">
                <g id="iconfinder_icon-printer_211880" transform="translate(-32 -64)">
                  <path id="Path_369" data-name="Path 369" d="M155.961,313.926h.541v-7.245a.7.7,0,0,0-.692-.681H144.692a.7.7,0,0,0-.692.681v7.245h11.961Z" transform="translate(-105.749 -228.493)"/>
                  <g id="Group_77" data-name="Group 77" transform="translate(32 64)">
                    <path id="Path_370" data-name="Path 370" d="M53.869,67.74H52.15V64.82a.856.856,0,0,0-.887-.82H37.743a.856.856,0,0,0-.887.82V67.74H35.137A3.34,3.34,0,0,0,32,70.787V77.53c0,1.518,1.58,3.444,3.137,3.444h1.021c.274,0,.307,0,.307-.781v-2.4A1.788,1.788,0,0,1,38.3,76.006H50.8c.988,0,1.736,1.189,1.736,2.233v1.954c0,.781.006.781.279.781h1.044a3.294,3.294,0,0,0,3.137-3V70.787A3.33,3.33,0,0,0,53.869,67.74Zm-1.328,3.4a.893.893,0,1,1,.893-.893A.9.9,0,0,1,52.54,71.144Z" transform="translate(-32 -64)"/>
                  </g>
                </g>
            </svg>
        </div>
    </div>
</div>