import { Component, OnInit } from '@angular/core';
import { NgxSmartModalService } from 'ngx-smart-modal';

@Component({
  selector: 'app-cash-in-transaction-logs',
  templateUrl: './cash-in-transaction-logs.component.html',
  styleUrls: ['./cash-in-transaction-logs.component.scss']
})
export class CashInTransactionLogsComponent implements OnInit {

  constructor(
    private modal: NgxSmartModalService,
  ) { }

  ngOnInit(): void {
    var tableHeader = document.getElementsByTagName("th");  
    for (var i = 0; i < tableHeader.length; i++) { 
      tableHeader[i].classList.add("headerSortDown");
    }
  }
  approve(){    let details = {
    edit_details: {
      title: "intererpelorts.megapay.ph says",
      content: "Are you sure you want to verify the transaction?",
      btn_cancel: "No",
      btn_save: "Yes",
    },
    purpose: 'Approve_transaction',
    comment_data: [],
    post_id : [],
    type :  [],
  }
  this.modal.setModalData(details, 'confirmationModal');
  this.modal.open('confirmationModal');
  }

  cancel(){    
    let details = {
			edit_details: {
				title: "intererpelorts.megapay.ph says",
				content: "Are you sure you want to cancel the transaction?",
				btn_cancel: "No",
				btn_save: "Yes",
			},
			purpose: 'cancel_transaction',
			comment_data: [],
      post_id : [],
      type :  [],
		}
		this.modal.setModalData(details, 'confirmationModal');
		this.modal.open('confirmationModal');
  }

}
