<div class="w-full h-full">
    <div class="grid grid-cols-12 gap-3">
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-60-px">
                    Name:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Name
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-140-px">
                    Phone Number:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Phone Number
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-70-px">
                    Address:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Address
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-40-px">
                    City:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    City
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-70-px">
                    Province:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Province
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-90-px">
                    Zip Code:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Zip Code
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-418-px">
                    Birth Date (yyyy-mm-dd):
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    yyyy-mm-dd
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-120-px">
                    Birth Place:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Birth Place
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-140-px">
                    Email Verified:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Email Verified
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-100-px">
                    Civil Status:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Civil Status
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-100-px">
                    Nationality:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Nationality
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-190-px">
                    Employment Status:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Employment Status
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-150-px">
                    Source of Fund:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Source of Fund
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-50-px">
                    Status:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Status
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-30-px">
                    Tier:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Tier
                </div>
            </div>
        </div>
        <div class="col-span-6 mb-3">
            <div class="pl-2 flex flex-col">
                <div class="text-xs">
                    Selfie Image
                </div>
                <div class="w-220-px h-190-px border-2 border-gray-300">

                </div>
            </div>
        </div>
        <div class="col-span-6 mb-3">
            <div class="pl-2 flex flex-col">
                <div class="text-xs">
                    ID Image
                </div>
                <div class="w-220-px h-190-px border-2 border-gray-300">

                </div>
            </div>
        </div>
    </div>
</div>