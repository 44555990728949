import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { RequestsService } from 'src/app/services/http/requests.service';
import { Urls } from 'src/app/lib/urls';
import { DebounceService } from 'src/app/services/debounce.service';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import {HttpClient, HttpParams } from'@angular/common/http';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sku-biller-all-transaction',
  templateUrl: './sku-biller-all-transaction.component.html',
  styleUrls: ['./sku-biller-all-transaction.component.scss']
})
export class SkuBillerAllTransactionComponent implements OnInit {

  public buyLoadForm: FormGroup;
  buyLoad_lists:any;
  isLoading: boolean;
  status:number;
  
  //add SKU
  isDisplay= true;
  toggleDisplay(){
    this.isDisplay= 
    !this.isDisplay;
  }
  
  // load providers
  providerDesc:any;
  providerDesc_text:any;

  // load provider
  provider:any;
  provider_text:any;

  // provider Type
  providerType:any;
  providerType_text:any;

  // item Type
  itemType:any;
  itemType_text:any;
 
  //pagination
  page=1;
  size=10;
  total_pages:any;
  current_page = 1;
  pages_array=[
  ];
  total:any;
  item:any;
  lastPage:any;

  //search
  searchKey: any=null;
  searchForm : FormGroup;
  search_keyword='';

  //limit
  limitKey:any=null;
  limitForm : FormGroup;
 
  constructor(
    private modal: NgxSmartModalService,
    private fb : FormBuilder,
    public debounce: DebounceService,
    private request: RequestsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private http:HttpClient,
    private route: ActivatedRoute,
  ) {
    this.initializeForm()
    }
   
  ngOnInit(): void {
  this.get_LoadProvider_list();
  this.get_buyLoad_list();
  // this.getProvider()
  // this.uid = this.route.snapshot.paramMap.get('id');

  }

  initializeForm(){
    this.buyLoadForm = this.fb.group({
      itemName: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      itemId: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      itemDesc: [
        '',
        Validators.compose([
          Validators.required
        ]) 
      ],
      itemPrice: [
        '',
        Validators.compose([
          Validators.required
        ])
      ], 
    });
    this.searchKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.search_keyword=this.searchForm.value.search;
      console.log('searchKeyword', this.search_keyword)
      this.get_buyLoad_list();
    }, 500); 

    this.searchForm = this.fb.group({
      search:['']
    });

    this.limitKey = this.debounce.debounce(() => {
      this.current_page=1;
      this.size=this.limitForm.value.limit;
      this.get_buyLoad_list();
    }, 500);  

    this.limitForm = this.fb.group({
      limit:['']
    });
  }
  // get_LoadProvider_list(){
  //   this.spinner.show();
  //   this.request.put(Urls.providers + '/' + this.provider, '').then(response => {
  //     if(response['status']==200){
  //       this.loadProvider_lists=response['result'];
  //       this.provider=response['result']['provider'];
  //       if(response['result']['provider']==0){
  //         this.providerDesc_text=' Default (Resound for Globe, Digilink for Smart/Sun/TNT)';
  //       }else if (response['result']['provider']==1) {
  //         this.providerDesc_text=' Digilink (Digilink For All Network) Load';
  //       }else {
  //         this.providerDesc_tex\t='Resound (Resound For All Network)';
  //       }
  //       setTimeout(() => {
  //         this.spinner.hide();
  //       }, 500);
        
  //     }
  //     console.log(response);
  //     this.toastr.success(response['message']);  
  //   }).finally(()=>{ 
  // });  
  // }

  get_LoadProvider_list(){
    this.request.get(Urls.providers + '/' ).then(response => {
       console.log(response);
     })  
   }

  store2(){
    this.spinner.show();
    this.request.post(Urls.store_buyload, {
      itemName:this.buyLoadForm.controls.itemName.value,
      itemId:this.buyLoadForm.controls.itemId.value,
      itemDesc:this.buyLoadForm.controls.itemDesc.value,
      itemPrice:this.buyLoadForm.controls.itemPrice.value,
      provider:this.provider,
      providerType:this.providerType,
      itemType:this.itemType,
    }).then(response => {
      console.log(response);
      this.page=this.lastPage;
      this.get_buyLoad_list();
      this.toastr.success(response['message']+'!', 'Succes!');
      setTimeout(() => {
        this.spinner.hide();
      }, 500);
    })
    this.buyLoadForm.reset();
    this.provider_text=null;
    this.providerType_text=null;
    this.itemType_text=null;
  } 
  
  get_buyLoad_list(){
    this.spinner.show();
    let params ={ 
      title : this.search_keyword,
    }
    this.pages_array=[];
    this.request.post(Urls.buyload + '?page=' + this.current_page + '&size=' + this.size, params).then(response => {
      if(response['status']==200){
        this.buyLoad_lists=response['result']['data'];
        this.isLoading=false;
        if(this.current_page % 3 === 0  ){
          this.pages_array=[];
          for(let i=this.current_page; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else if(this.current_page==1){
          for(let i=1; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else if(this.current_page==response['result']['totalPages']){
          for(let i=this.current_page-2; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }else{
          for(let i=this.current_page-1; i<=response['result']['totalPages'];i++){
            this.pages_array.push(i);
          };
        }
        this.total_pages=parseInt(response['result']['totalPages']);
        this.current_page=response['result']['currentPage'];
        this.total=parseInt(response['result']['totalItems']);
        setTimeout(() => {
          this.spinner.hide();
        }, 500);
      }
    }).finally(()=>{
    });   
  }

  setLoadProvider(text,value){
    this.providerDesc=value;
    this.providerDesc_text=text;
    
  }

  setProvider(text,value){
    this.provider=value;
    this.provider_text=text;
  }

  setProviderType(text,value){
    this.providerType=value;
    this.providerType_text=text;
  }

  setItemType(text,value){ 
    this.itemType=value;
    this.itemType_text=text; 
  }

  setPage(num){
    this.current_page = num;
    this.get_buyLoad_list();
  }
  nextPage(){
    if(this.current_page < Number(this.total_pages)){
         this.current_page++;
         this.get_buyLoad_list()
     }
   }

  prevPage(){
    if(this.current_page <= Number(this.total_pages)){
      this.current_page--;
      this.get_buyLoad_list();
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.searchList();
    }
  }

  searchList(){
    this.current_page=1;
    this.search_keyword=this.searchForm.value.search;
    console.log('searchKeyword', this.search_keyword)
    this.get_buyLoad_list();
  }

  setheader(index){
    localStorage.setItem('navigate', index);
  } 

  changeStatus(id){
    if(this.status == 1 ){
      this.status = 0;
    }else{
      this.status = 1;
    }
    this.request.put(Urls.buyload + '/' + id,{
      status:this.status
      }).then(response => {
      console.log(response);
      this.get_buyLoad_list()
      this.toastr.success(response['message']+'!', 'Succes!');
    })
  }

  switchLoadProvider(){
    this.request.put(Urls.providers + '/' + '?provider=' + this.providerDesc ,'').then(response => {
      console.log(response); 
      this. get_LoadProvider_list(); 
      this.toastr.success(response['message']+'!', 'Succes!');
      

      
    }) 
    
  
  }
}


