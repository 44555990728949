<div class="h-full w-full">
    <div class="px-8 py-5">
        <!-- tabs -->
        <div class="flex items-center space-x-4 text-sm justify-start mb-3">
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 1 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(1)">
                KYC REGISTRATION
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 2 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(2)">
                KYC LOGS
            </button>
        </div>
    </div>
    <!-- rendered tab -->
    <div class="w-full h-full">
        <ng-container *ngIf="selected_tab == 1">
            <app-kyc-registration></app-kyc-registration>
        </ng-container>
        <ng-container *ngIf="selected_tab == 2">
            <app-kyc-logs></app-kyc-logs>
        </ng-container>
    </div>

</div>