<div class="h-full w-full">
    <div class="px-8 py-5">
        <!-- tabs -->
        <div class="flex items-center space-x-1 text-sm justify-start mb-3">
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 1 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(1)">
                cash in transaction
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 2 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(2)">
                cash in transaction logs
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 3 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(3)">
                cash out transaction
            </button>
        </div>
    </div>
    <!-- rendered tab -->
    <div class="w-full h-full">
        <ng-container *ngIf="selected_tab == 1">
            <app-cash-in-transaction-logs></app-cash-in-transaction-logs>
        </ng-container>
        <ng-container *ngIf="selected_tab == 2">
            <app-cash-in-transaction></app-cash-in-transaction>
        </ng-container>
        <ng-container *ngIf="selected_tab == 3">
            <app-cash-out-transaction></app-cash-out-transaction>
        </ng-container>
        
    </div>

</div>