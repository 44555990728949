import { Injectable, Inject} from '@angular/core';
import { environment as envs } from 'src/app/environments/environment'
import { cookie } from './cookie';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';

@Injectable({providedIn: 'root'})

export class environment {

    private secure = true;
    public subdomain = envs.subdomain;
    public domain =  'development.megapay.com.ph';

    public url = envs.api_url.replace('https', 'http');
    public secureUrl = envs.api_url;
   

    private devicetoken = 'e8EabPzF7Qo:APA91bEL4v42XxB7mcuLz1H-fZjmJhc1oYbswLz_BPNDwjZaR1xdIpYvw2N2LaGyXzH3HxiwF-HT-2oKDmceW0PH7mF1xjcBvqh9-kMsatetBnKN8BrWsL15v12efg38Wbn8lbhn-Vhw';
    public deviceid = '';

    public emailValidator = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    build_type: any = envs.build_type;

    constructor(
        @Inject(DOCUMENT)private document : Document, 
        public http : HttpClient, 
        public cookie : cookie,
    ) {}    


    getCookie(name : string): any {
        return this.cookie.getCookie(this.subdomain + name);
    }

    setCookie(name: any,data: any, day: number = 0): any {
        this.cookie.setCookie(this.subdomain + name, data, day, '/', this.domain);
    }

    setToken(token : any, day : number = 0): any {
        localStorage.setItem(this.subdomain + 'token', token);
        this.cookie.setCookie(this.subdomain + 'token', token, day, '/', this.domain); 
    }

    deleteCookie(): any {
        this.cookie.deleteCookie(this.subdomain + 'deviceid', this.domain);
        this.cookie.deleteCookie(this.subdomain + 'token', this.domain);
        this.cookie.deleteCookie(this.subdomain + 'user_profile', this.domain);
        localStorage.clear(); 
    }
    
    exchangeToken(/* deviceid?: any */) {
        console.log("deviceid", this.deviceid);
        return new Promise((resolve)=>{
            let token = this.getCookie('token');
            console.log("exchangeToken token", token);
            if (!this.deviceid) {
                this.setDeviceId('no-device-' + this.uuidv4());
            }/*  else {
                this.setDeviceId(this.deviceid);
            } */
            let url = this.getUrl('/api/token/get');
            this.http.get(url, this.getHttpOptions()).subscribe((result: any) => {
                let r: any = result;
                this.setToken(r.data.token, 60);
                this.setCookie('deviceid', this.deviceid, 60);
                resolve(r.data.token);
            }, (error) => {
                //console.log('result',error);
            });
            /* if (!token.length) {
                if (!deviceid) {
                    this.setDeviceId('no-device-' + this.uuidv4());
                } else {
                    this.setDeviceId(deviceid);
                }
                let url = this.getUrl('/api/token/get');
                this.http.get(url, this.getHttpOptions()).subscribe((result: any) => {
                    let r: any = result;
                    this.setToken(r.data.token, 60);
                    this.setCookie('deviceid', this.deviceid, 60);
                    resolve(r.data.token);
                }, (error) => {
                    //console.log('result',error);
                });
            } else {
                resolve(token);
            } */
        })  
    }

    getHttpOptions() {
        let deviceid = this.getCookie('deviceid');
        let username = JSON.parse(localStorage.getItem('username'));
        let password = JSON.parse(localStorage.getItem('password'));
        if(deviceid){
            this.setDeviceId(deviceid);
        }
        let httpOptions = {
            headers: new HttpHeaders(
                {
                    'Authorization': 'Basic ' + btoa(username+':'+password)
                }
            ),
        };
        return httpOptions;
    }



    setDeviceId(str){
        this.deviceid = str;
    }

    getUrl(path : string): any {
        let url = '';
        if (this.secure == true) {
            url += this.secureUrl;
        } else {
            url += this.url;
        } url += path;
        return url;
    };

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
    b64toBlob(b64Data, contentType='', sliceSize=512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];
    
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
    
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
    
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
    
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      }
      counter(i: number) {
        return new Array(i);
      }
}
