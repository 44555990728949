<div class="h-full w-full">
    <div class="px-8 py-5">
        <!-- tabs -->
        <div class="flex items-center space-x-1 text-sm justify-start mb-3">
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 1 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(1)">
                Buy load
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 2 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(2)">
                pay bills
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 3 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(3)">
                stores
            </button>
            <button class="custom-btn focus:outline-none py-3 px-2 uppercase font-semibold {{ selected_tab == 4 ? 'btn-dark-orange' : 'btn-light-orange'}}" (click)="selectTab(4)">
                redeem
            </button>
        </div>
    </div>
    <!-- rendered tab -->
    <div class="w-full h-full">
        <ng-container *ngIf="selected_tab == 1">
            <app-sku-biller-all-transaction></app-sku-biller-all-transaction>
        </ng-container>
        <ng-container *ngIf="selected_tab == 2">
            <app-sku-pay-bills></app-sku-pay-bills>
        </ng-container>
        <ng-container *ngIf="selected_tab == 3">
            <app-sku-stores></app-sku-stores>
        </ng-container>
        <ng-container *ngIf="selected_tab == 4">
            <app-sku-redeem></app-sku-redeem>
        </ng-container>
    </div>
</div>