import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { RequestsService } from 'src/app/services/http/requests.service';
import { FormBuilder, FormGroup , Validators } from '@angular/forms';
import * as moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/app/lib/environment';
import { ToastrService } from 'ngx-toastr';


// import { NgxSpinnerService } from "ngx-spinner";

import { Urls } from 'src/app/lib/urls';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  show = true;
  login_password_type: string = 'password';
  show_forgot = false;
  public loginForm: FormGroup;
  constructor(
    private route: Router,
    public userService: UserService,
    public request: RequestsService,
    private fb : FormBuilder,
    private toastr: ToastrService,
    private http: HttpClient,
    private env: environment
  ) {
    this.loginForm = this.fb.group({
      username: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
      password: [
        '',
        Validators.compose([
          Validators.required
        ])
      ],
    });
  }

  ngOnInit(): void {
  }

  showLoginPassword(bool) {
    this.show = bool;
    //console.log('boolean', bool);
    if (bool) {
      this.login_password_type = 'text';
    } else {
      this.login_password_type = 'password';
    }
  }
  forgotPass(){
    this.show_forgot = !this.show_forgot
  }

  login(){
    let httpOptions = {
      headers: new HttpHeaders(
          {
              'Authorization': 'Basic ' + btoa(this.loginForm.controls.username.value+':'+this.loginForm.controls.password.value)
          }
      )
    };
    let params ={ 
      webViewUrl : '',
    }
    this.http.post(this.env.getUrl(Urls.announcements), params, httpOptions).subscribe(
      result => {
        // console.log(result['error']['message'])
        if(!result['error']){
          this.toastr.success('You have logged in Successfuly!', 'Succes!');
          localStorage.setItem('username', JSON.stringify(this.loginForm.controls.username.value));
          localStorage.setItem('password', JSON.stringify(this.loginForm.controls.password.value));
          this.route.navigate(['']); 
        }
      },
      error => {
        this.toastr.error(error['error']['message']+'!', 'Something went wrong!');
      }
    );
    
  }
}
