
// Modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { LoginPageModule } from './pages/login/login-page.module';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material/material.module';
import { TemplateComponent } from './layout/template/template.component';
import { SidenavComponent } from './layout/sidenav/sidenav.component';
import { HeaderComponent } from './layout/header/header.component';
import { ChangePassComponent } from './component/change-pass/change-pass.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { TransactionsComponent } from './component/transactions/transactions.component';
import { AllTransactionComponent } from './component/all-transaction/all-transaction.component';
import { BuyLoadComponent } from './component/buy-load/buy-load.component';
import { CashInComponent } from './component/cash-in/cash-in.component';
import { PayBillsComponent } from './component/pay-bills/pay-bills.component';
import { TransferFundsComponent } from './component/transfer-funds/transfer-funds.component';
import { StorePurchaseComponent } from './component/store-purchase/store-purchase.component';
import { MegaRewardComponent } from './component/mega-reward/mega-reward.component';
import { PromoComponent } from './component/promo/promo.component';
import { OverviewComponent } from './component/overview/overview.component';
import { AccountsComponent } from './component/accounts/accounts.component';
import { AccountViewComponent } from './component/account-view/account-view.component';
import { AccountsInformationComponent } from './component/accounts-information/accounts-information.component';
import { AccountsKycDetailComponent } from './component/accounts-kyc-detail/accounts-kyc-detail.component';
import { AccountsEditComponent } from './component/accounts-edit/accounts-edit.component';
import { KycComponent } from './component/kyc/kyc.component';
import { KycRegistrationComponent } from './component/kyc-registration/kyc-registration.component';
import { KycLogsComponent } from './component/kyc-logs/kyc-logs.component';
import { MegapayFeaturesComponent } from './component/megapay-features/megapay-features.component';
import { FeaturesBillerComponent } from './component/features-biller/features-biller.component';
import { SkuBillerAllTransactionComponent } from './component/sku-biller-all-transaction/sku-biller-all-transaction.component';
import { SkuPayBillsComponent } from './component/sku-pay-bills/sku-pay-bills.component';
import { SkuStoresComponent } from './component/sku-stores/sku-stores.component';
import { SkuRedeemComponent } from './component/sku-redeem/sku-redeem.component';
import { FeaturesPushNotifComponent } from './component/features-push-notif/features-push-notif.component';
import { FeaturesAnnouncementComponent } from './component/features-announcement/features-announcement.component';
import { AdsDashboardComponent } from './component/ads-dashboard/ads-dashboard.component';
import { AdsDashboardScreenComponent } from './component/ads-dashboard-screen/ads-dashboard-screen.component';
import { AdsDashboardLinkComponent } from './component/ads-dashboard-link/ads-dashboard-link.component';
import { FeaturesProfanityFilterComponent } from './component/features-profanity-filter/features-profanity-filter.component';
import { FeaturesAutoDisableLogsComponent } from './component/features-auto-disable-logs/features-auto-disable-logs.component';
import { FeaturesRdpdDashboardComponent } from './component/features-rdpd-dashboard/features-rdpd-dashboard.component';
import { FeaturesReferalComponent } from './component/features-referal/features-referal.component';
import { CashinOutComponent } from './component/cashin-out/cashin-out.component';
import { CashInTransactionComponent } from './component/cash-in-transaction/cash-in-transaction.component';
import { CashInTransactionLogsComponent } from './component/cash-in-transaction-logs/cash-in-transaction-logs.component';
import { CashOutTransactionComponent } from './component/cash-out-transaction/cash-out-transaction.component';
import { AdminComponent } from './component/admin/admin.component';
import { AdminBuyloadComponent } from './component/admin-buyload/admin-buyload.component';
import { AdminTpLogsComponent } from './component/admin-tp-logs/admin-tp-logs.component';
import { AdminTpPayBillsComponent } from './component/admin-tp-pay-bills/admin-tp-pay-bills.component';
import { AdminTpCashInComponent } from './component/admin-tp-cash-in/admin-tp-cash-in.component';
import { AdminTpStoresComponent } from './component/admin-tp-stores/admin-tp-stores.component';
import { AdminTpRedeemComponent } from './component/admin-tp-redeem/admin-tp-redeem.component';
import { AdminSystemMaintenanceComponent } from './component/admin-system-maintenance/admin-system-maintenance.component';
import { KycFormComponent } from './component/kyc-form/kyc-form.component';
import { ModalComponent } from './modal/confirmation-modal/confirmation_modal.component';
import { HttpClientModule } from '@angular/common/http';
import { 
  MAT_MOMENT_DATE_FORMATS, MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter'; 
import {
  DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE  } from '@angular/material/core'; 
import * as moment from 'moment';
import { EditAdsScreenComponent } from './component/edit-ads-screen/edit-ads-screen.component';
import { EditAdsLinkComponent } from './component/edit-ads-link/edit-ads-link.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";


@NgModule({
  declarations: [
    AppComponent,
    TemplateComponent,
    SidenavComponent,
    HeaderComponent,
    ChangePassComponent,
    DashboardComponent,
    TransactionsComponent,
    AllTransactionComponent,
    BuyLoadComponent,
    CashInComponent,
    PayBillsComponent,
    TransferFundsComponent,
    StorePurchaseComponent,
    MegaRewardComponent,
    PromoComponent,
    OverviewComponent,
    AccountsComponent,
    AccountViewComponent,
    AccountsInformationComponent,
    AccountsKycDetailComponent,
    AccountsEditComponent,
    KycComponent,
    KycRegistrationComponent,
    KycLogsComponent,
    FeaturesBillerComponent,
    MegapayFeaturesComponent,
    SkuBillerAllTransactionComponent,
    SkuPayBillsComponent,
    SkuStoresComponent,
    SkuRedeemComponent,
    FeaturesPushNotifComponent,
    FeaturesAnnouncementComponent,
    AdsDashboardComponent,
    AdsDashboardScreenComponent,
    AdsDashboardLinkComponent,
    FeaturesProfanityFilterComponent,
    FeaturesAutoDisableLogsComponent,
    FeaturesRdpdDashboardComponent,
    FeaturesReferalComponent,
    CashinOutComponent,
    CashInTransactionComponent,
    CashInTransactionLogsComponent,
    CashOutTransactionComponent,
    AdminComponent,
    AdminBuyloadComponent,
    AdminTpLogsComponent,
    AdminTpPayBillsComponent,
    AdminTpCashInComponent,
    AdminTpStoresComponent,
    AdminTpRedeemComponent,
    AdminSystemMaintenanceComponent,
    KycFormComponent,
    ModalComponent,
    EditAdsScreenComponent,
    EditAdsLinkComponent,
  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LoginPageModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule, //REST-API Module 
    NgxSmartModalModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass :'toast-top-right'
    }),
  ],
  exports: [BrowserAnimationsModule],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'}, // optional but gets my dates in dd/mm/yy format
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true}},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS}, 
  
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
