import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnChanges {
  title: any;
  navigation_id:any = 0;
  navigation_title:any;
  title_is_array: any = false;
  constructor(
    private router: Router
  ) { 
    let route_title = this.router.url.split('/')
    this.title = route_title[1]

    this.router.events.subscribe((ev) => {
      if (ev instanceof NavigationEnd) { 
          this.navigation_id = JSON.parse(localStorage.getItem('navigate'));
          console.log('navigation_id: ', this.navigation_id)
          
          switch (this.navigation_id) {
            case 0:
                this.title_is_array = false;
                this.navigation_title = "DashBoard";
              break;
            case 1:
                this.title_is_array = false;
                this.navigation_title = "Transactions";
              break;
            case 2:
                this.title_is_array = false;
                this.navigation_title = "Accounts";
              break;
            case 3:
                this.title_is_array = true;
                this.navigation_title = [
                  {
                    title: "Sku's/billers dashboard",
                    route: "/features/biller-dashboard"
                  },
                  {
                    title: "push notification",
                    route: "/features/push-notification"
                  },
                  {
                    title: "announcement",
                    route: "/features/announcement"
                  },
                  {
                    title: "ads dashboard",
                    route: "/features/ads-dashboard"
                  },
                  {
                    title: "profanity filter",
                    route: "/features/profanity-filter"
                  },
                  {
                    title: "auto disable logs",
                    route: "/features/disable-logs"
                  },
                  {
                    title: "rd/pd dashboard",
                    route: "/features/rd-pd-dashboard"
                  },
                  {
                    title: "referrals",
                    route: "/features/referrals"
                  },
                ];
              break;
            case 4:
                this.title_is_array = false;
                this.navigation_title = "KYC";
              break;
            case 5:
                this.title_is_array = false;
                this.navigation_title = 'Cash In / Out';
              break;
            case 6:
                this.title_is_array = true;
                this.navigation_title = [
                  {
                    title: "third party logs",
                    route: "/admin/third-party-log"
                  },
                  {
                    title: "system maintenance",
                    route: "/admin/system-maintenance"
                  },
                  {
                    title: "portal account",
                    route: "portal-account"
                  },
                  {
                    title: "portal user logs",
                    route: "portal-log"
                  },
                ];
              break;
            case 7:
                this.title_is_array = true;
                this.navigation_title = [
                  {
                    title: "summary reports",
                    route: "summary-reports"
                  },
                  {
                    title: "daily sales reports",
                    route: "daily-sales-reports"
                  },
                  {
                    title: "top user",
                    route: "top-user"
                  },
                  {
                    title: "referal commission",
                    route: "referal-commission"
                  },
                  {
                    title: "branch commission",
                    route: "branch-commission"
                  },
                ];
              break;
          }
        }
      });
  }
  ngOnChanges(){
  }

  ngOnInit(): void {
  }
  

}
