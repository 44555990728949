<div class="w-full h-full">
    <div class="grid grid-cols-12 gap-3">
        <div class="col-span-4">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-120-px">
                    First Name:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    First Name
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-140-px">
                    Middle Name:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Middle Name
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-120-px">
                    Last Name:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Last Name
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-140-px">
                    Phone Number:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Phone Number
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-140-px">
                    Email:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Email
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-140-px">
                    Register Date:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Register Date
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-140-px">
                    Referer Name:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Referer Name
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-190-px">
                    Referrer Number:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Referrer Number
                </div>
            </div>
        </div>
        <div class="col-span-12">
            <hr class="border border-gray-300 w-full my-1 mb-3">
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-120-px">
                    Tier:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Default
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-140-px">
                    Active:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Yes
                </div>
            </div>
        </div>
        <div class="col-span-4">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-140-px">
                    Email Verified:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Yes
                </div>
            </div>
        </div>
        <div class="col-span-12">
            <hr class="border border-gray-300 w-full my-1 mb-3">
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-140-px">
                    Card Number:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Card Number
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-170-px">
                    Cardholder Name:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Cardholder Name
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-140-px">
                    Card Email:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Card Email
                </div>
            </div>
        </div>
        <div class="col-span-6">
            <div class="card-input flex items-center text-sm py-2 w-full mb-3">
                <span class="text-sm w-140-px">
                    Time:
                </span>
                <div class=" focus:outline-none w-full h-30-px bg-gray-100 rounded-md pl-3 italic flex items-center">
                    Time
                </div>
            </div>
        </div>

    </div>
</div>